import { Container, DatePicker, FormField, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react-v3';
import React from 'react';

interface IGeneralSettingsContainerProps {
  name: string;
  date: string;
  notes: string;
  handleSetName: React.Dispatch<React.SetStateAction<string>>;
  handleSetDate: React.Dispatch<React.SetStateAction<string>>;
  handleSetNotes: React.Dispatch<React.SetStateAction<string>>;
}

const GeneralSettingsContainer = (props: IGeneralSettingsContainerProps) => {
  const required: string = 'Required';
  const optional: string = 'Optional';

  return (
    <Container data-testid="container-generalsettings" key="a" header="General Settings">
      <SpaceBetween size="s">
        <FormField
          description="Provide a descriptive, meaningful name. You will be able to search by it."
          label="Test Suite Name"
          constraintText={required}
        >
          <Input value={props.name} onChange={(event) => props.handleSetName(event.detail.value)} />
        </FormField>
        <FormField
          description="Set a date, to emphasize when this test suite should be finished."
          label="Need By Date"
          constraintText={required}
        >
          <DatePicker
            onChange={({ detail }) => props.handleSetDate(detail.value)}
            value={props.date}
            nextMonthAriaLabel="Next month"
            placeholder="YYYY/MM/DD"
            previousMonthAriaLabel="Previous month"
            todayAriaLabel="Today"
          />
        </FormField>
        <FormField
          description="You can search by text in this field in the Browse Suites section."
          label="Notes"
          constraintText={optional}
        >
          <Textarea
            onChange={({ detail }) => props.handleSetNotes(detail.value)}
            value={props.notes}
            placeholder="Add notes here..."
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
export default GeneralSettingsContainer;
