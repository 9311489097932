import { Box, Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { navigate } from '@reach/router';
import React from 'react';

export class ErrorBoundary extends React.Component<{}, { error: any; errorInfo: any }> {
  constructor(props: { error: any; errorInfo: any }) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Box padding="xxl">
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate('/');
                        this.setState({ error: null, errorInfo: null });
                      }}
                    >
                      Go to Home
                    </Button>
                  </SpaceBetween>
                }
              >
                Something went wrong.
              </Header>
            }
          >
            {this.state.error && JSON.stringify(this.state.error)}
          </Container>
        </Box>
      );
    }
    return this.props.children;
  }
}
