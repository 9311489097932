import { HelpPanel } from "@amzn/awsui-components-react-v3";
import React from "react";

interface ISuitesCreateHelpPanelContent {
}

const SuitesCreateHelpPanelContent = (props: ISuitesCreateHelpPanelContent) => {

    return (
        <HelpPanel
            header={<h2>Create Suite</h2>}
        >
            <div>
                <p>Fill in the <i>General Settings</i> as well as <i>Configurations to be tested</i>. </p>
                <h4>General Settings</h4>
            Mandatory parameters:
                <ul>
                    <li>Test Suite Name.</li>
                    <li>Need By Date.</li>
                </ul>
                <h4>Configurations to be tested</h4>
                <p>At least one configuration needs to be added create a Test Suite.</p>
                <p>Select desired configuration from the dropdowns and click<b> Add</b> to add.
                You can only add <i>unique configurations.</i></p>
                <p>Select <i>unwanted</i> configuration and click <b>Remove selected</b> to remove it.</p>
                <p>Clear the whole table with <b>Remove all configurations</b> button.</p>
            </div>
        </HelpPanel>
    );
}
export default SuitesCreateHelpPanelContent;