import { Box, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { getConfigurationsStatus } from '../../../util/ConfigurationUtil';
import { IConfiguration } from '../IConfiguration';
import ConfigurationCard from './ConfigurationCard';

interface ISuiteDetailsProps {
  configurations: IConfiguration[];
  suiteId: string;
}

export const SuiteDetails = (props: ISuiteDetailsProps) => {
  let [inProgressConfigs, notStartedConfigs, completedConfigs] = getConfigurationsStatus(props.configurations);

  return (
    <Box data-testid="container-suitedetails">
      <ExpandableSection header={`In progress (${inProgressConfigs.length})`} defaultExpanded={true}>
        <SpaceBetween size="l">
          {inProgressConfigs.map((configuration) => {
            return <ConfigurationCard key={configuration.id} configuration={configuration} suiteId={props.suiteId} />;
          })}
        </SpaceBetween>
      </ExpandableSection>
      <ExpandableSection header={`Not started (${notStartedConfigs.length})`}>
        <SpaceBetween size="l">
          {notStartedConfigs.map((configuration) => {
            return <ConfigurationCard key={configuration.id} configuration={configuration} suiteId={props.suiteId} />;
          })}
        </SpaceBetween>
      </ExpandableSection>
      <ExpandableSection header={`Completed (${completedConfigs.length})`}>
        <SpaceBetween size="l">
          {completedConfigs.map((configuration) => {
            return <ConfigurationCard key={configuration.id} configuration={configuration} suiteId={props.suiteId} />;
          })}
        </SpaceBetween>
      </ExpandableSection>
    </Box>
  );
};
export default SuiteDetails;
