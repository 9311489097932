import {
  Box,
  BreadcrumbGroup,
  BreadcrumbGroupProps,
  Button,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { navigate, RouteComponentProps, useLocation, WindowLocation } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import SuitesCreateHelpPanelContent from '../../../hoc/SuitesCreateHelpPanelContent';
import { createSuite, ICreateSuiteRequest } from '../../../remote/suites';
import { convertConfigurationsToQueries } from '../../../util/ConfigurationUtil';
import { getUTCDateString } from '../../../util/stringutil';
import HelpPanelInfoIcon from '../../Help/HelpPanelInfoIcon';
import { IConfiguration } from '../IConfiguration';
import { IQuery } from '../IQuery';
import ConfigurationsContainer from './ConfigurationsContainer';
import GeneralSettingsContainer from './GeneralSettingsContainer';
import QueryContainer from './QueryContainer';

interface ISuitesCreateProps {
  queries?: IQuery[];
  configurations?: IConfiguration[];
  breadcrumbs?: BreadcrumbGroupProps.Item[];
}

const SuitesCreate = (props: ISuitesCreateProps & RouteComponentProps) => {
  const location: WindowLocation<any> = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [date, setDate] = useState<string>(new Date(Date.now()).toISOString());
  const [notes, setNotes] = useState('');
  const [configurations, setConfigurations] = useState<IConfiguration[]>(
    props.configurations ? props.configurations : []
  );
  const [queries, setQueries] = useState<IQuery[]>(props.queries ? props.queries : []);
  const { showErrorFlashbar, showSuccessFlashbar, showInProgressFlashbar } = useContext(NotificationContext);
  const { idToken } = useContext(AuthorizationContext);

  const resetForm = () => {
    setName('');
    setDate(new Date(Date.now()).toISOString());
    setNotes('');
    setConfigurations([]);
    setQueries([]);
  };

  const createSuiteRequest: ICreateSuiteRequest = {
    testSuites: [
      {
        title: name,
        neededBy: getUTCDateString(date),
        note: notes,
        configurations: [...convertConfigurationsToQueries(configurations), ...queries],
      },
    ],
  };

  useEffect(() => {
    if (location.state && location.state.queries) {
      setQueries(location.state.queries);
    }
  }, [location]);

  const handleCreateSuite = () => {
    showInProgressFlashbar('Creating suite...');
    setIsLoading(true);
    createSuite(createSuiteRequest, idToken)
      .then(() => {
        setIsLoading(false);
        showSuccessFlashbar('Test Suite created.');
        navigate('/suites/browse');
        resetForm();
      })
      .catch((e) => {
        showErrorFlashbar(`
                Creating suite FAILED with error ${JSON.stringify(e)}. Try again.If problem persists, cut us a SIM.`);
      });
  };
  const isFormNotFilled = (): boolean => {
    return (configurations.length === 0 && queries.length === 0) || name === '' || date === '';
  };

  return (
    <Box data-testid="container-suitescreate" padding="xxl">
      {props.breadcrumbs && <BreadcrumbGroup items={props.breadcrumbs} />}
      <Form
        header={
          <Header variant="h1">
            Create Test Suite {<HelpPanelInfoIcon content={<SuitesCreateHelpPanelContent />} />}
          </Header>
        }
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={handleCreateSuite} disabled={isFormNotFilled() || isLoading}>
              Create Suite
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="s">
          <GeneralSettingsContainer
            name={name}
            handleSetName={setName}
            date={date}
            handleSetDate={setDate}
            notes={notes}
            handleSetNotes={setNotes}
          />
          <Box color="text-body-secondary">At least one configuration or query is required to create a Test Suite.</Box>
          <ExpandableSection header="Create TestSuite using manual configuration">
            <ConfigurationsContainer configurations={configurations} handleSetConfigurations={setConfigurations} />
          </ExpandableSection>
          <ExpandableSection header="Or, create Test Suite using query" defaultExpanded={true}>
            <SpaceBetween direction="vertical" size="s">
              <QueryContainer queries={queries} handleSetQueries={setQueries} />
            </SpaceBetween>
          </ExpandableSection>
        </SpaceBetween>
      </Form>
    </Box>
  );
};
export default SuitesCreate;
