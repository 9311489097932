import {
  Box,
  Button,
  Container,
  Grid,
  ProgressBar,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { Link, RouteComponentProps } from '@reach/router';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { RequestError } from '../../../remote/request';
import { getConfigurationById, postTestcases } from '../../../remote/suites';
import { IConfiguration } from '../IConfiguration';
import {
  getTestCaseStatusFromConfiguration,
  getTotalNumberOfTestCasesFromConfiguration,
  isQueryDone,
  isQueryFailed,
  isQueryInProgress,
} from '../SuiteDataAggregation';
import ConfigurationCardTitle from './ConfigurationCardTitle';

interface IConfigurationCardProps extends RouteComponentProps {
  configuration: IConfiguration;
  suiteId: string;
}

const ConfigurationCard = (props: IConfigurationCardProps) => {
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const { showErrorFlashbar, showInProgressFlashbar, clearFlashbar, showSuccessFlashbar } = useContext(
    NotificationContext
  );
  const [enableFetch, setEnableFetch] = useState(isQueryInProgress(props.configuration));
  const [isLoading, setIsLoading] = useState(isQueryInProgress(props.configuration));
  const queryClient = useQueryClient();
  const [passed, failed, blocked, untested] = getTestCaseStatusFromConfiguration(props.configuration);
  const additionalInfo = `Passed: ${passed}, Failed: ${failed}, Blocked: ${blocked}, Untested ${untested}`;
  const total = getTotalNumberOfTestCasesFromConfiguration(props.configuration);
  const progress = ((passed + failed + blocked) / total) * 100;

  const renderProgressBar = () => {
    return (
      <ProgressBar
        status={progress === 100 ? 'success' : 'in-progress'}
        value={progress}
        additionalInfo={additionalInfo}
        label={progress === 100 ? '' : 'Test Run Progress'}
        resultText="Completed"
      />
    );
  };

  const renderFetchResultsPrompt = () => {
    return (
      <TextContent>
        Click <strong>Run</strong> to fetch Test Cases
      </TextContent>
    );
  };

  const runConfiguration = useMutation<
    { configuration: IConfiguration },
    RequestError,
    { idToken: string; suiteId: string; configurationId: string }
  >(
    'runConfiguration',
    (runConfigurationOptions) =>
      postTestcases(
        runConfigurationOptions.idToken,
        runConfigurationOptions.suiteId,
        runConfigurationOptions.configurationId
      ),
    {
      onSuccess: () => {
        setEnableFetch(true);
        queryClient.invalidateQueries('getSuiteById');
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not fetch test cases for: [${JSON.stringify({
            configurationId: variables.configurationId,
            suiteId: variables.suiteId,
          })}]. Error occurred: [${error.message}]`
        );
      },
      onMutate: () => {
        setIsLoading(true);
        showInProgressFlashbar(`Fetching test cases...`);
      },
    }
  );

  useQuery(
    'getConfigurationById',
    () => getConfigurationById(idToken, props.suiteId!, props.configuration.id!, true, true),
    {
      enabled: enableFetch,
      refetchInterval: 3000,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        reset();
        showErrorFlashbar(
          `Failed to get configuration [${props.configuration.id}]. Error occurred: [${error.message}].`
        );
      },
      onSuccess: (configuration: IConfiguration) => {
        if (isQueryFailed(configuration)) {
          reset();
          showErrorFlashbar(
            `Failed to run configuration [${props.configuration.id}]. Provided query might be invalid.`
          );
        } else if (isQueryDone(configuration)) {
          reset();
          showSuccessFlashbar(`Fetched ${getTotalNumberOfTestCasesFromConfiguration(configuration)} test cases.`);
        }
      },
    }
  );

  const reset = () => {
    setEnableFetch(isQueryInProgress(props.configuration));
    setIsLoading(isQueryInProgress(props.configuration));
    clearFlashbar();
    queryClient.invalidateQueries('getSuiteById');
  };

  const handleRunConfiguration = () => {
    runConfiguration.mutate({
      idToken: idToken,
      suiteId: props.suiteId,
      configurationId: props.configuration.id!,
    });
  };

  return (
    <Container>
      <Grid gridDefinition={[{ colspan: { default: 12, xs: 9 } }, { colspan: { default: 12, xs: 3 } }]}>
        <SpaceBetween direction="vertical" size="xl">
          <ConfigurationCardTitle configuration={props.configuration} />
          {getTotalNumberOfTestCasesFromConfiguration(props.configuration) > 0
            ? renderProgressBar()
            : renderFetchResultsPrompt()}
        </SpaceBetween>
        <Box padding="l" textAlign="center">
          {getTotalNumberOfTestCasesFromConfiguration(props.configuration) > 0 ? (
            <Link to={props.configuration.id!}>
              <Button>View</Button>
            </Link>
          ) : isLoading ? (
            <Spinner />
          ) : (
            <Button
              onClick={() => {
                handleRunConfiguration();
              }}
              variant="primary"
            >
              Run
            </Button>
          )}
        </Box>
      </Grid>
    </Container>
  );
};
export default ConfigurationCard;
