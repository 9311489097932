export const cognitoConfig = {
  domain: 'bifrost2',
  region: 'us-west-2',
  jwksEndpoint:
    'https://cognito-idp.us-west-2.amazonaws.com/us-west-2_CeJqRahKr/.well-known/jwks.json',
  redirectURL: {
    beta: 'https://beta.verifeye.tools.a2z.com',
    prod: 'https://verifeye.tools.a2z.com',
    dev: 'http://localhost:3000'
  },
  beta: {
    appClientId: '68noc47ln69mirh3fj6r9ehau9',
    redirectURL: 'https://beta.verifeye.tools.a2z.com',
    hostname: 'beta.verifeye.tools.a2z.com'
  },
  prod: {
    appClientId: '58osajpki88meahprbbfti0qdm',
    redirectURL: 'https://verifeye.tools.a2z.com',
    hostname: 'verifeye.tools.a2z.com'
  },
  dev: {
    appClientId: '2natjqbeuqpqkq0k3dila886vd',
    redirectURL: 'http://localhost:3000',
    hostname: 'localhost:3000'
  }
};
