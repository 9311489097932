import { Box, Button } from '@amzn/awsui-components-react-v3';
import { navigate } from '@reach/router';
import React from 'react';
const GoToHomeButton = () => {
  return (
    <Box>
      <Button
        data-testid="button-home"
        onClick={() => {
          navigate('/');
        }}
      >
        Go to Home
      </Button>
    </Box>
  );
};
export default GoToHomeButton;
