import { ITestRun } from './ITestOnDemand';
import { Country, deviceToTestGroupMap, getRegion, isClassic, Region } from './SignupTodConfig';
import { ITodParams } from './TestOnDemandCreate/TestOnDemandCreate';
import { DeviceType, TodRunConfigurationGenerator } from './TodRunConfigurationGenerator';
import { SignupTodRunParameter } from './TodRunParameter';

export class SignupTodRunConfigurationGenerator extends TodRunConfigurationGenerator {
  createTestRuns(params: ITodParams): ITestRun[] {
    let testRuns: ITestRun[] = [];
    params.device.forEach((d) => {
      this.generateTargets(d, params.country, params.locale).forEach((t) => {
        testRuns.push({ runParameter: new SignupTodRunParameter(t) });
      });
    });
    return testRuns;
  }
  private generateTargets(deviceType: DeviceType, country: Country[], locale: string[]) {
    let targets: string[] = [];
    deviceToTestGroupMap[deviceType][isClassic(country[0]) ? 'classic' : 'row'].forEach((g) => {
      targets.push(
        `${(getRegion(
          country[0]
        ) as Region).toLowerCase()} bifrost_prod ${g} -Dtestrun.country=${country} -Dtestrun.locale=${locale
          .map((l) => {
            return l.replace('_', '-');
          })
          .join(',')} run`
      );
    });
    return targets;
  }
}
