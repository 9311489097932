import { Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps } from '@reach/router';
import React, { useContext } from 'react';
import AuthorizationContext from '../../hoc/AuthorizationContext';
import TestOnDemandBrowse from './TestOnDemandBrowse/TestOnDemandBrowse';
import TestOnDemandCreate from './TestOnDemandCreate/TestOnDemandCreate';
import { getWebsocketApiUrl } from './utils';

export interface TestsOnDemandProps extends RouteComponentProps {}

const TestsOnDemand = (props: TestsOnDemandProps) => {
  const { idToken } = useContext(AuthorizationContext);
  const initializeWebSocket = () => {
    return new WebSocket(`${getWebsocketApiUrl()}?id_token=${idToken}`);
  };

  return (
    <Box padding="l" textAlign="center">
      <SpaceBetween direction="vertical" size="l">
        <TestOnDemandCreate />
        <TestOnDemandBrowse websocket={initializeWebSocket} />
      </SpaceBetween>
    </Box>
  );
};
export default TestsOnDemand;
