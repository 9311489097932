import {
  Box,
  Button,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
  Table,
  TableProps,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { Link } from '@reach/router';
import React from 'react';
import {
  getCountryFromQueryString,
  getDeviceFromQueryString,
  getLocalDateString,
  getLocaleFromQueryString,
  getPageFromQueryString,
} from '../../../util/stringutil';
import EmptyBox from '../EmptyBox';
import { IConfiguration } from '../IConfiguration';
import { ISuite } from '../ISuite';
import NoMatch from '../NoMatch';
import { ValueWithLabel } from '../Suite/ValueWithLabel';
import { filterDefaultConfigs, filterQueryConfigs } from '../../../util/ConfigurationUtil';

const queryColumnDefinitions: TableProps.ColumnDefinition<IConfiguration>[] = [
  { id: 'queryTitle', header: 'Title', cell: (item) => item.queryTitle },
  { id: 'query', header: 'Query', cell: (item) => item.query },
  { id: 'queryType', header: 'Query Type', cell: (item) => item.queryType },
];

const defaultColumnDefinitions: TableProps.ColumnDefinition<IConfiguration>[] = [
  {
    id: 'device',
    header: 'Device',
    cell: (item) => (item.device ? item.device : item.query ? getDeviceFromQueryString(item.query) : ''),
  },
  {
    id: 'country',
    header: 'Country',
    cell: (item) => (item.country ? item.country : item.query ? getCountryFromQueryString(item.query!) : ''),
  },
  {
    id: 'page',
    header: 'Page',
    cell: (item) => (item.page ? item.page : item.query ? getPageFromQueryString(item.query!) : ''),
  },
  {
    id: 'locale',
    header: 'Locale',
    cell: (item) => (item.locale ? item.locale : item.query ? getLocaleFromQueryString(item.query!) : ''),
  },
];

export const cardsConfig = {
  cardDefinition: {
    header: (item: ISuite) => (
      <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
        <Link to={item.id!}>
          <Header>{item.title}</Header>
        </Link>
        <Box float="right">
          <ValueWithLabel label="Needed By">
            <TextContent>
              <strong>{getLocalDateString(item.neededBy)}</strong>
            </TextContent>
          </ValueWithLabel>
        </Box>
      </Grid>
    ),
    sections: [
      {
        id: 'description',
        content: (item: ISuite) => (
          <ExpandableSection header={`Preview configurations (${item.configurations.length})`}>
            <SpaceBetween size="l">
              {filterDefaultConfigs(item.configurations).length > 0 ? (
                <Table columnDefinitions={defaultColumnDefinitions} items={filterDefaultConfigs(item.configurations)} />
              ) : null}
              {filterQueryConfigs(item.configurations).length > 0 ? (
                <Table columnDefinitions={queryColumnDefinitions} items={filterQueryConfigs(item.configurations)} />
              ) : null}
            </SpaceBetween>
          </ExpandableSection>
        ),
      },
    ],
  },
  emptyCard: (
    <>
      <EmptyBox boldText="No suites found" />
      <Link to="/suites/create">
        <Button>Create</Button>
      </Link>
    </>
  ),
  noMatch: <NoMatch />,
};
