import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  ButtonProps,
  Container,
  FormField,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Table,
  TableProps,
  Textarea,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import React, { useContext, useState } from 'react';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { QueryTypeOptions } from '../../ResultsDashboard/FilterSideBar/FiltersConfig';
import { IQuery } from '../IQuery';
import { QueryType } from '../QueryType';

interface IQueryContainerProps {
  queries: IQuery[];
  handleSetQueries: React.Dispatch<React.SetStateAction<IQuery[]>>;
}

const QueryContainer = (props: IQueryContainerProps) => {
  const { clearFlashbar, showWarningFlashbar } = useContext(NotificationContext);
  const { items, collectionProps } = useCollection(props.queries, {
    sorting: {},
  });
  const [selectedQueryRows, setSelectedQueryRows] = useState<IQuery[]>([]);
  const [titleQuery, setTitleQuery] = useState<string>('');
  const [query, setQuery] = useState<IQuery>({
    query: '',
    queryType: QueryType.ES_LUCENE,
    metadata: undefined,
    queryTitle: '',
  });
  const [selectedQueryType, setSelectedQueryType] = useState<SelectProps.Option>(QueryTypeOptions[0]);
  const columnDefinitions: TableProps.ColumnDefinition<IQuery>[] = [
    {
      id: 'title',
      header: 'Title',
      cell: (item) => item.queryTitle,
    },
    {
      id: 'query',
      header: 'Query',
      cell: (item) => item.query,
    },
    {
      id: 'queryType',
      header: 'Query Type',
      cell: (item) => item.queryType,
    },
  ];

  const deleteSelectedQueryTableRows = (items: IQuery[]) => {
    const q = [...props.queries];
    const filtered = q.filter((q) => !JSON.stringify(items).includes(JSON.stringify(q)));
    props.handleSetQueries(filtered);
  };
  const handleAddQuery = (e: CustomEvent<ButtonProps.ClickDetail>) => {
    e.preventDefault();
    if (isQueryEmpty(query)) {
      showWarningFlashbar(`Query must not be empty.`);
      return;
    }
    if (queryExists(props.queries, query)) {
      showWarningFlashbar(
        `Query ${JSON.stringify({
          query: query.query,
          queryTitle: query.queryTitle,
          queryType: selectedQueryType.value,
        })} already exists.`
      );
      return;
    }
    clearFlashbar();
    props.handleSetQueries([...props.queries, query]);
  };
  const queryExists = (queries: IQuery[], query: IQuery) => {
    return (
      queries.filter(
        (q) =>
          JSON.stringify({ query: q.query.trim(), queryTitle: q.queryTitle?.trim(), queryType: q.queryType }) ===
          JSON.stringify({
            query: query.query.trim(),
            queryTitle: query.queryTitle?.trim(),
            queryType: query.queryType,
          })
      ).length > 0
    );
  };

  const isQueryEmpty = (query: IQuery) => {
    return query.query.trim().length === 0;
  };

  const handleQueryTitle = (title: string) => {
    setTitleQuery(title);
    setQuery({ ...query, queryTitle: title });
  };

  return (
    <Container header={`Queries to be tested (${props.queries.length})`}>
      <SpaceBetween size="s">
        <FormField>
          <Table
            {...collectionProps}
            columnDefinitions={columnDefinitions}
            items={items}
            empty={
              <Box textAlign="center" color="inherit">
                <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                  <b>Add</b> queries by typing in the textbox below. <br />
                </Box>
              </Box>
            }
            selectionType="multi"
            onSelectionChange={({ detail }) => {
              setSelectedQueryRows(detail.selectedItems);
            }}
            selectedItems={selectedQueryRows}
          />
        </FormField>

        <FormField constraintText="Optional">
          <TextContent>Title</TextContent>
          <Input value={titleQuery} onChange={({ detail }) => handleQueryTitle(detail.value)} />
        </FormField>

        <FormField constraintText="Required">
          <TextContent>Query</TextContent>
          <Textarea
            onChange={({ detail }) =>
              setQuery({
                query: detail.value,
                queryTitle: titleQuery,
                queryType: QueryType.ES_LUCENE,
                metadata: { isDefault: false },
              })
            }
            value={query.query}
          />
        </FormField>

        <FormField>
          <TextContent>Select a query type</TextContent>
          <SpaceBetween direction="horizontal" size="l">
            <Select
              triggerVariant="label"
              selectedOption={selectedQueryType}
              onChange={({ detail }) => {
                setSelectedQueryType(detail.selectedOption);
              }}
              options={QueryTypeOptions}
              placeholder="Select Query Type"
              filteringType="auto"
            />
          </SpaceBetween>
        </FormField>
        <SpaceBetween direction="horizontal" size="s">
          <Button variant="normal" onClick={handleAddQuery}>
            Add
          </Button>
          <Button
            variant="normal"
            onClick={(e) => {
              deleteSelectedQueryTableRows(selectedQueryRows);
            }}
            disabled={selectedQueryRows.length === 0}
          >
            Remove selected
          </Button>
          <Button
            variant="normal"
            onClick={(e) => {
              e.preventDefault();
              props.handleSetQueries([]);
            }}
          >
            Remove all queries
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};
export default QueryContainer;
