import { PageType } from './TodRunConfigurationGenerator';

export const pageToTargetMap: Record<PageType, string[]> = {
  billing: ['primeandchannelcardentry', 'primecardentry'],
  confirmation: [
    'channelconfirmation',
    'primeconfirmation',
    'promotionchannelconfirmation',
    'primewithchannelconfirmation',
    'primemultipplanconfirmation',
  ],
  details: ['channeldetailpage', 'tvoddetailpage', 'primedetailpage', 'parentalcontrol', 'promotiondetail'],
  help: [],
  mlp: [],
  mystuff: ['mystuffsvod', 'mystufftvod'],
  payment: [],
  planselection: [],
  playback: [],
  search: ['searchpage'],
  settings: ['settingspage'],
  storefront: ['storefront'],
};
