import { Link } from '@amzn/awsui-components-react-v3';
import React from 'react';
import logo from './../../assets/bifrost-logo.png';
import './Header.css';

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  return (
    <header>
      <Link href="/">
        <img src={logo} className="header-logo" alt="Logo" />
      </Link>
    </header>
  );
};

export default Header;
