import { cognitoConfig } from '../util/cognitoconfig';
import CookieService from '../util/CookieService';
import { encryptStringWithSHA256, getRandomString, hashToBase64url } from '../util/stringutil';
import { isTokenValid } from './tokenverifier';

let headers = new Headers();
headers.set('Cache-Control', 'no-store');
let urlParams = new URLSearchParams(window.location.search);

const getHostName = () => {
  return window.location.hostname;
};

const getAppClientId = () => {
  if (getHostName() === 'localhost') {
    return cognitoConfig.dev.appClientId;
  } else if (getHostName() === 'beta.verifeye.tools.a2z.com') {
    return cognitoConfig.beta.appClientId;
  } else if (getHostName() === 'verifeye.tools.a2z.com') {
    return cognitoConfig.prod.appClientId;
  }
};

const getRedirectURI = () => {
  if (getHostName() === 'localhost') {
    return cognitoConfig.dev.redirectURL;
  } else if (getHostName() === 'beta.verifeye.tools.a2z.com') {
    return cognitoConfig.beta.redirectURL;
  } else if (getHostName() === 'verifeye.tools.a2z.com') {
    return cognitoConfig.prod.redirectURL;
  }
};

export const getLoginPage = (): string => {
  return window.location.origin;
};

function handleCookie(idToken: string) {
  const expiresInMins = 480 * 60 * 1000;
  let date = new Date();
  date.setTime(date.getTime() + expiresInMins);
  const options = { path: '/', expires: date, sameSite: 'Lax', secure: true };
  CookieService.set('id_token', idToken, options);
}

export async function authorizeuser() {
  let code = urlParams.get('code');
  let state = urlParams.get('state');
  if (code === null || state === null) {
    state = getRandomString();
    sessionStorage.setItem('pkce_state', state);
    const code_verifier: string | null = getRandomString();
    sessionStorage.setItem('code_verifier', code_verifier);
    const arrayHash = await encryptStringWithSHA256(code_verifier);
    const code_challenge = hashToBase64url(arrayHash);
    sessionStorage.setItem('code_challenge', code_challenge);

    window.location.href =
      'https://' +
      cognitoConfig.domain +
      '.auth.' +
      cognitoConfig.region +
      '.amazoncognito.com/oauth2/authorize?response_type=code&state=' +
      state +
      '&client_id=' +
      getAppClientId() +
      '&redirect_uri=' +
      getRedirectURI() +
      '&scope=openid&code_challenge_method=S256&code_challenge=' +
      code_challenge;
  } else if (sessionStorage.getItem('pkce_state') !== state) {
    CookieService.remove('id_token');
  } else {
    const existingIdToken = CookieService.get('id_token');
    if (existingIdToken === undefined || !isTokenValid(existingIdToken)) {
      const stored_code_verifier = sessionStorage.getItem('code_verifier');
      const resp: any = await fetch(
        'https://' +
          cognitoConfig.domain +
          '.auth.' +
          cognitoConfig.region +
          '.amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=' +
          getAppClientId() +
          '&code_verifier=' +
          stored_code_verifier +
          '&redirect_uri=' +
          getRedirectURI() +
          '&code=' +
          code,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const respText = await resp.json();
      const idToken = respText.id_token;
      if (idToken !== undefined && isTokenValid(idToken)) {
        handleCookie(idToken);
        return idToken;
      }
    }
  }
}
