import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  ButtonProps,
  Container,
  ExpandableSection,
  FormField,
  Multiselect,
  MultiselectProps,
  Select,
  SelectProps,
  SpaceBetween,
  Table,
  TableProps,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import React, { useContext, useState } from 'react';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { generateConfigurations } from '../../../util/permutate';
import {
  CountryOptions,
  DeviceTypeOptions,
  LocaleOptions,
  PageTypeOptions,
} from '../../ResultsDashboard/FilterSideBar/FiltersConfig';
import { IConfiguration } from '../IConfiguration';

interface IConfigurationsContainerProps {
  configurations: IConfiguration[];
  handleSetConfigurations: React.Dispatch<React.SetStateAction<IConfiguration[]>>;
}

const ConfigurationsContainer = (props: IConfigurationsContainerProps) => {
  const [selectedConfigurationRows, setSelectedConfigurationRows] = useState<IConfiguration[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<SelectProps.Option>(DeviceTypeOptions[0]);
  const [selectedCountry, setSelectedCountry] = useState<SelectProps.Option>(CountryOptions[0]);
  const [selectedPage, setSelectedPage] = useState<SelectProps.Option>(PageTypeOptions[0]);
  const [selectedLocale, setSelectedLocale] = useState<SelectProps.Option>(LocaleOptions[0]);
  const [selectedDeviceMulti, setSelectedDeviceMulti] = useState<MultiselectProps.Options>([]);
  const [selectedCountryMulti, setSelectedCountryMulti] = useState<MultiselectProps.Options>([]);
  const [selectedPageMulti, setSelectedPageMulti] = useState<MultiselectProps.Options>([]);
  const [selectedLocaleMulti, setSelectedLocaleMulti] = useState<MultiselectProps.Options>([]);
  const { clearFlashbar, showWarningFlashbar } = useContext(NotificationContext);
  const { items, collectionProps } = useCollection(props.configurations, {
    sorting: {},
  });
  const deleteSelectedConfigurationTableRows = (items: IConfiguration[]) => {
    const c = [...props.configurations];
    const filtered = c.filter((conf) => !JSON.stringify(items).includes(JSON.stringify(conf)));
    props.handleSetConfigurations(filtered);
  };

  const columnDefinitions: TableProps.ColumnDefinition<IConfiguration>[] = [
    {
      id: 'device',
      header: 'Device Type',
      cell: (item) => item.device,
      sortingField: 'device',
    },
    {
      id: 'country',
      header: 'Country',
      cell: (item) => item.country,
      sortingField: 'country',
    },
    {
      id: 'page',
      header: 'Page',
      cell: (item) => item.page,
      sortingField: 'page',
    },
    {
      id: 'locale',
      header: 'Locale',
      cell: (item) => item.locale,
      sortingField: 'locale',
    },
  ];

  const configurationExists = (configurations: IConfiguration[], configuration: IConfiguration) => {
    return configurations.filter((c) => JSON.stringify(c) === JSON.stringify(configuration)).length > 0;
  };
  const handleAddConfiguration = (e: CustomEvent<ButtonProps.ClickDetail>) => {
    e.preventDefault();
    if (
      configurationExists(props.configurations, {
        device: selectedDevice.value,
        country: selectedCountry.value,
        page: selectedPage.value,
        locale: selectedLocale.value,
      })
    ) {
      showWarningFlashbar(
        `Configuration ${JSON.stringify({
          deviceType: selectedDevice.value,
          country: selectedCountry.value,
          page: selectedPage.value,
          locale: selectedLocale.value,
        })} already exists.`
      );
      return;
    }
    clearFlashbar();
    props.handleSetConfigurations([
      ...props.configurations,
      {
        device: selectedDevice.value,
        country: selectedCountry.value,
        page: selectedPage.value,
        locale: selectedLocale.value,
      },
    ]);
  };

  const handleGenerateConfigurations = (e: CustomEvent<ButtonProps.ClickDetail>) => {
    e.preventDefault();
    const generatedConfigurations = generateConfigurations([
      selectedDeviceMulti.map((elem: OptionDefinition) => {
        return elem.value!;
      }),
      selectedCountryMulti.map((elem: OptionDefinition) => {
        return elem.value!;
      }),
      selectedPageMulti.map((elem: OptionDefinition) => {
        return elem.value!;
      }),
      selectedLocaleMulti.map((elem: OptionDefinition) => {
        return elem.value!;
      }),
    ]);
    if (generatedConfigurations.length > 500) {
      showWarningFlashbar("That's too many...");
      return;
    }
    props.handleSetConfigurations([
      ...props.configurations,
      ...generatedConfigurations.filter((configuration) => {
        return !configurationExists(props.configurations, configuration);
      }),
    ]);
  };

  return (
    <Container
      data-testid="container-configurations"
      key="b"
      header={`Configurations to be tested (${props.configurations.length})`}
    >
      <SpaceBetween direction="vertical" size="s">
        <FormField description="Select combinations of device/page/country/locale to be added to the suite.">
          <Table
            {...collectionProps}
            columnDefinitions={columnDefinitions}
            items={items}
            empty={
              <Box textAlign="center" color="inherit">
                <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                  <b>Add</b> configurations using controls below. <br />
                </Box>
              </Box>
            }
            selectionType="multi"
            onSelectionChange={({ detail }) => {
              setSelectedConfigurationRows(detail.selectedItems);
            }}
            selectedItems={selectedConfigurationRows}
          />
        </FormField>

        <FormField>
          <SpaceBetween direction="horizontal" size="s">
            <Select
              triggerVariant="label"
              selectedOption={selectedDevice}
              onChange={({ detail }) => {
                setSelectedDevice(detail.selectedOption);
              }}
              options={DeviceTypeOptions}
              placeholder="Select Device"
              filteringType="auto"
            />
            <Select
              selectedOption={selectedCountry}
              onChange={({ detail }) => {
                setSelectedCountry(detail.selectedOption);
              }}
              options={CountryOptions}
              placeholder="Select Country"
              filteringType="auto"
            />
            <Select
              selectedOption={selectedPage}
              onChange={({ detail }) => {
                setSelectedPage(detail.selectedOption);
              }}
              options={PageTypeOptions}
              placeholder="Select Page"
              filteringType="auto"
            />
            <Select
              selectedOption={selectedLocale}
              onChange={({ detail }) => {
                setSelectedLocale(detail.selectedOption);
              }}
              options={LocaleOptions}
              placeholder="Select Device Type"
              filteringType="auto"
            />
          </SpaceBetween>
        </FormField>
        <SpaceBetween direction="horizontal" size="s">
          <Button variant="normal" onClick={handleAddConfiguration}>
            Add
          </Button>
          <Button
            variant="normal"
            onClick={(e) => {
              deleteSelectedConfigurationTableRows(selectedConfigurationRows);
            }}
            disabled={selectedConfigurationRows.length === 0}
          >
            Remove selected
          </Button>
          <Button
            variant="normal"
            onClick={(e) => {
              e.preventDefault();
              props.handleSetConfigurations([]);
            }}
          >
            Remove all configurations
          </Button>
        </SpaceBetween>
        <ExpandableSection header="Generate configurations">
          <SpaceBetween direction="vertical" size="s">
            <FormField>
              <SpaceBetween direction="vertical" size="s">
                <Multiselect
                  selectedOptions={selectedDeviceMulti}
                  onChange={({ detail }) => setSelectedDeviceMulti(detail.selectedOptions)}
                  deselectAriaLabel={(e) => 'Remove ' + e.value}
                  options={DeviceTypeOptions}
                  placeholder="Select device"
                  selectedAriaLabel="Selected"
                />
                <Multiselect
                  selectedOptions={selectedCountryMulti}
                  onChange={({ detail }) => setSelectedCountryMulti(detail.selectedOptions)}
                  deselectAriaLabel={(e) => 'Remove ' + e.value}
                  options={CountryOptions}
                  placeholder="Select country"
                  selectedAriaLabel="Selected"
                />
                <Multiselect
                  selectedOptions={selectedPageMulti}
                  onChange={({ detail }) => setSelectedPageMulti(detail.selectedOptions)}
                  deselectAriaLabel={(e) => 'Remove ' + e.value}
                  options={PageTypeOptions}
                  placeholder="Select page"
                  selectedAriaLabel="Selected"
                />
                <Multiselect
                  selectedOptions={selectedLocaleMulti}
                  onChange={({ detail }) => setSelectedLocaleMulti(detail.selectedOptions)}
                  deselectAriaLabel={(e) => 'Remove ' + e.value}
                  options={LocaleOptions}
                  placeholder="Select locale"
                  selectedAriaLabel="Selected"
                />
              </SpaceBetween>
            </FormField>
            <Button
              onClick={handleGenerateConfigurations}
              disabled={
                selectedDeviceMulti.length === 0 ||
                selectedPageMulti.length === 0 ||
                selectedCountryMulti.length === 0 ||
                selectedLocaleMulti.length === 0
              }
            >
              Generate configurations
            </Button>
          </SpaceBetween>
        </ExpandableSection>
      </SpaceBetween>
    </Container>
  );
};
export default ConfigurationsContainer;
