export abstract class TodRunParameter {
  abstract target: string;
  abstract srcPackage: string;
  abstract versionSet: string;
  todWorkerName: string = 'TodWorker/PVInternationalQATodWorker';
  todWorkerStage: string = 'Prod';
  platform: 'AL2_x86_64' | 'AL2012' = 'AL2_x86_64';
  arguments: string = '';
  majorVersion: string = '1.0';
  timeout: number = 60;
}
export class LivingRoomTodRunParameter extends TodRunParameter {
  target: string;
  srcPackage: string = 'PVScreenJourneysLivingRoom';
  versionSet: string = 'PVScreenJourneysLivingRoom/development';
  constructor(target: string) {
    super();
    this.target = target;
  }
}
export class SignupTodRunParameter extends TodRunParameter {
  target: string;
  srcPackage: string = 'PVScreenJourneysSignupWebTest';
  versionSet: string = 'PVScreenJourneysSignupWeb/development';
  constructor(target: string) {
    super();
    this.target = target;
  }
}
