import { Box } from '@amzn/awsui-components-react-v3';
import React from 'react';

const NoMatch = () => {
  return (
    <Box textAlign="center" color="inherit">
      <b>No data</b>
      <Box padding={{ bottom: 's' }} variant="p" color="inherit">
        Nothing to display.
      </Box>
    </Box>
  );
};

export default NoMatch;
