import { IConfiguration } from '../components/Suites/IConfiguration';

/**
 * Function will generate configurations based on selected device, country, page and locale.
 * @param options selected configurations
 * @returns array of IConfiguration
 */
export const generateConfigurations = (options: string[][]) => {
  let permutations: IConfiguration[] = [],
    max = options.length - 1;
  function helper(arr: string[], i: number) {
    for (let j = 0, l = options[i].length; j < l; j++) {
      let a: string[] = arr.slice(0);
      a.push(options[i][j]);
      if (i === max) permutations.push({ device: a[0], country: a[1], page: a[2], locale: a[3] });
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return permutations;
};
