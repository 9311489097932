import { SpaceBetween, TextContent, Toggle } from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';

interface IConfigurationCardTitleTextContentProps {
  title?: string;
  query?: string;
}
const ConfigurationCardQueryTitleTextContent = (props: IConfigurationCardTitleTextContentProps) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);

  return (
    <TextContent>
      <SpaceBetween size="s">
        {Boolean(props.title) && (
          <SpaceBetween size="s">
            <h3>{props.title}</h3>
            <Toggle onChange={({ detail }) => setShowQuery(detail.checked)} checked={showQuery}>
              {showQuery ? 'Hide query' : 'Show query'}
            </Toggle>
          </SpaceBetween>
        )}
        {showQuery && props.query}
        {!props.title && props.query}
      </SpaceBetween>
    </TextContent>
  );
};

export default ConfigurationCardQueryTitleTextContent;
