import { Router } from '@reach/router';
import React from 'react';
import { ErrorBoundary } from '../../hoc/ErrorBoundary';
import Run from '../Suites/Runs/Run';
import Suite from '../Suites/Suite/Suite';
import SuitesClone from '../Suites/SuitesCreate/SuitesClone';
import SuitesEdit from '../Suites/SuitesCreate/SuitesEdit';
import TestCase from '../Suites/TestCases/TestCase';
import TestOnDemand from '../TestOnDemand/TestOnDemand';
import Home from './Home';
import NotFoundPage from './NotFoundPage';
const SuitesCreate = React.lazy(() => import('../Suites/SuitesCreate/SuitesCreate'));
const SuitesBrowse = React.lazy(() => import('../Suites/SuitesBrowse/SuitesBrowse'));
const ResultsDashboard = React.lazy(() => import('../ResultsDashboard/ResultsDashboard'));

const Routes = () => {
  return (
    <ErrorBoundary data-testid="container-errorboundary">
      <Router>
        <NotFoundPage default={true} />
        <Home path="/" />
        <ResultsDashboard path="journeys/browse" />
        <SuitesCreate
          path="suites/create"
          breadcrumbs={[
            { text: 'Suites', href: 'browse' },
            { text: 'Create', href: 'create' },
          ]}
        />
        <SuitesClone path="suites/browse/:suiteId/clone" />
        <SuitesEdit path="suites/browse/:suiteId/edit" />
        <SuitesBrowse path="suites/browse" />
        <Suite path="suites/browse/:suiteId" />
        <Run path="suites/browse/:suiteId/:configurationId" />
        <TestCase path="suites/browse/:suiteId/:configurationId/:testResultId" />
        <TestOnDemand path="testondemand/browse" />
      </Router>
    </ErrorBoundary>
  );
};
export default Routes;
