import { ButtonDropdown, ColumnLayout, Container, Header, TextContent } from '@amzn/awsui-components-react-v3';
import { navigate } from '@reach/router';
import * as React from 'react';
import { getLocalDateString } from '../../../util/stringutil';
import { ISuite } from '../ISuite';
import { ValueWithLabel } from './ValueWithLabel';

interface ISuiteHeaderProps {
  suite: ISuite;
}

export const SuiteHeader = (props: ISuiteHeaderProps) => {
  return (
    <Container
      data-testid="container-suiteheader"
      header={
        <Header
          variant="h2"
          actions={
            <ButtonDropdown
              items={[
                { text: 'Copy to new', id: 'clone', disabled: false },
                { text: 'Edit', id: 'edit', disabled: false },
              ]}
              onItemClick={(event) => {
                navigate(`/suites/browse/${props.suite.id}/${event.detail.id}`);
              }}
            >
              Actions
            </ButtonDropdown>
          }
        >
          {props.suite.title}
        </Header>
      }
    >
      <ColumnLayout columns={4} variant="text-grid">
        <ValueWithLabel label="Needed By">
          <TextContent>{getLocalDateString(props.suite.neededBy!)}</TextContent>
        </ValueWithLabel>
        <ValueWithLabel label="Notes">{props.suite.note}</ValueWithLabel>
        <ValueWithLabel label="Created At">{getLocalDateString(props.suite.createdAt!)}</ValueWithLabel>
        <ValueWithLabel label="Updated At">{getLocalDateString(props.suite.updatedAt!)}</ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};
export default SuiteHeader;
