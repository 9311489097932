import TestcaseCards from './TestCaseCards';
import TestcaseDetailsMetadataMap from './TestcaseDetailsMetadataMap';
import { Tabs } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { ITestCaseResult } from '../SuiteDataAggregation';
import { getAdditionalDataLocale } from '../../../util/TestCaseUtils';

interface TestcaseTabsProps {
  testCaseResult: ITestCaseResult;
  showLocale: boolean;
}
const TestCaseTabs = (props: TestcaseTabsProps) => (
  <Tabs
    tabs={[
      {
        label: 'Images',
        id: 'image',
        disabled: props.testCaseResult.screenshots && props.testCaseResult.screenshots.length === 0,
        content: (
          <TestcaseCards
            screenshots={props.testCaseResult.screenshots!}
            locale={props.showLocale ? getAdditionalDataLocale(props.testCaseResult) : ''}
          />
        ),
      },
      {
        label: 'Metadata',
        id: 'metadatamap',
        content: <TestcaseDetailsMetadataMap testResultMetadataMap={props.testCaseResult.testResultMetadataMap} />,
      },
    ]}
  />
);
export default TestCaseTabs;
