import {
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  ExpandableSection,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { RequestError } from '../../../remote/request';
import { getSuiteById, getTestcases } from '../../../remote/suites';
import { getBreadcrumbItemsFromLocation } from '../../../util/stringutil';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import '../../ResultsDashboard/Results/Cards/CardImage/CardImage.css';
import SuiteHeader from '../Suite/SuiteHeader';
import {
  filterByTestResultId,
  getConfigurationFromSuite,
  getTotalNumberOfTestCasesFromTestCaseResults,
  ITestCaseFilter,
  ITestCaseResult,
  ITestCaseStatus,
  getTestCaseStatusFromConfiguration,
  filterTestResultsByTestCaseStatus,
  findIntersectionOf,
} from '../SuiteDataAggregation';
import TestCaseStatusChart from '../TestCaseStatusChart';
import NoScreenshotTestCaseTable from '../TestCases/NoScreenshotTestCaseTable';
import RefetchTestCaseModal from '../TestCases/RefetchTestCaseModal';
import CommonTestCaseFilters from '../TestCases/CommonTestCaseFilters';
import ConfigurationCardTitle from '../Suite/ConfigurationCardTitle';
import ScreenshotTestCaseCards from '../TestCases/ScreenshotTestCaseCards';

interface IRunProps extends RouteComponentProps {
  configurationId?: string;
  suiteId?: string;
}

export const getStatusIconForStatus = (status: ITestCaseStatus) => {
  switch (status) {
    case 'PASSED':
      return <StatusIndicator type="success">Passed</StatusIndicator>;
    case 'FAILED':
      return <StatusIndicator type="error">Failed</StatusIndicator>;
    case 'BLOCKED':
      return (
        <StatusIndicator type="warning" colorOverride="blue">
          Blocked
        </StatusIndicator>
      );
    case 'UNTESTED':
      return <StatusIndicator type="stopped">Untested</StatusIndicator>;
    default:
      return <StatusIndicator type="stopped">Untested</StatusIndicator>;
  }
};

const Run = (props: IRunProps) => {
  const [allTestcases, setAllTestcases] = useState<ITestCaseResult[]>([]);
  const [screenshotTestcases, setScreenshotTestcases] = useState<ITestCaseResult[]>([]);
  const [noScreenshotTestcases, setNoScreenshotTestcases] = useState<ITestCaseResult[]>([]);
  const [expandNoScreenshotSection, setExpandNoScreenshotSection] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ITestCaseResult[]>([]);
  const [checkBoxFilters, setCheckBoxFilters] = useState<ITestCaseFilter>({
    passed: false,
    failed: false,
    blocked: false,
    untested: false,
  });
  const [textFilter, setTextFilter] = useState<string>('');
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const { showErrorFlashbar } = useContext(NotificationContext);
  const location = useLocation();
  const suiteQuery = useQuery('getSuiteById', () => getSuiteById(props.suiteId!, idToken, true, true), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error: RequestError) => {
      handleStatusCode(error.status);
      showErrorFlashbar(`Failed to fetch suite with id [${props.suiteId}].`);
    },
  });
  const testcasesQuery = useQuery(
    'getTestcases',
    () => getTestcases(idToken, props.suiteId!, props.configurationId!, new URLSearchParams('?groupBy=screenshots')),
    {
      refetchOnWindowFocus: false,
      retry: 5,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Failed to get configuration [${props.configurationId}]. Error occurred: [${error.message}]. `
        );
      },
      onSuccess: (data) => {
        setScreenshotTestcases(data.testResults.screenshotTestResults!);
        setNoScreenshotTestcases(data.testResults.noScreenshotTestResults!);
        setAllTestcases([...data.testResults.screenshotTestResults!, ...data.testResults.noScreenshotTestResults!]);
      },
    }
  );
  const [passed, failed, blocked, untested] = suiteQuery.isSuccess
    ? getTestCaseStatusFromConfiguration(getConfigurationFromSuite(props.configurationId!, suiteQuery.data))
    : [0, 0, 0, 0];
  const total = getTotalNumberOfTestCasesFromTestCaseResults(testcasesQuery.data?.testResults!);
  const showError = () => {
    return <GoToHomeButton />;
  };
  const getConfiguration = () => {
    return suiteQuery.isSuccess && getConfigurationFromSuite(props.configurationId!, suiteQuery.data) ? (
      <ConfigurationCardTitle configuration={getConfigurationFromSuite(props.configurationId!, suiteQuery.data)} />
    ) : (
      <Spinner size="normal" />
    );
  };
  const checkBoxResults = filterTestResultsByTestCaseStatus(allTestcases, checkBoxFilters);
  const textFilterResults = filterByTestResultId(allTestcases, textFilter);
  const filteredTestcases = textFilterResults.length > 0 ? textFilterResults : checkBoxResults;

  const content = (
    <SpaceBetween direction="vertical" size="l">
      <BreadcrumbGroup items={getBreadcrumbItemsFromLocation(location.pathname)} />
      {suiteQuery.isSuccess && <SuiteHeader suite={suiteQuery.data} />}
      {testcasesQuery.isSuccess && (
        <TestCaseStatusChart
          testCaseStatusCount={{ passed: passed, failed: failed, blocked: blocked, untested: untested, total: total }}
        />
      )}
      <Container
        header={
          <ColumnLayout columns={2}>
            <SpaceBetween size="s">{getConfiguration()}</SpaceBetween>
            <CommonTestCaseFilters
              testcases={allTestcases}
              selectedItems={selectedItems}
              checkBoxFilters={checkBoxFilters}
              setVisible={setVisible}
              setCheckBoxFilters={setCheckBoxFilters}
              setTextFilter={setTextFilter}
              setExpandNoScreenshotSection={setExpandNoScreenshotSection}
            />
          </ColumnLayout>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <ExpandableSection
            header={`No Screenshots (${findIntersectionOf(filteredTestcases, noScreenshotTestcases).length})`}
            defaultExpanded={expandNoScreenshotSection}
          >
            <NoScreenshotTestCaseTable
              testcases={noScreenshotTestcases}
              configurationId={props.configurationId!}
              suiteId={props.suiteId!}
              visible={visible}
              selectedItems={selectedItems}
              filteredTestcases={filteredTestcases}
              setSelectedItems={setSelectedItems}
              setVisible={setVisible}
            />
          </ExpandableSection>
          <ExpandableSection
            header={`Screenshots (${findIntersectionOf(filteredTestcases, screenshotTestcases).length})`}
            defaultExpanded={true}
          >
            <ScreenshotTestCaseCards
              testcases={screenshotTestcases}
              visible={visible}
              selectedItems={selectedItems}
              filteredTestcases={filteredTestcases}
              setVisible={setVisible}
              setSelectedItems={setSelectedItems}
            />
          </ExpandableSection>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );

  return (
    <Box padding="l" textAlign="center">
      {testcasesQuery.isError && showError()}
      {testcasesQuery.isLoading && <Spinner size="large" />}
      {testcasesQuery.isSuccess && content}
      <RefetchTestCaseModal
        containsTestcases={!!testcasesQuery.data}
        configurationId={props.configurationId!}
        selectedItems={selectedItems}
        suiteId={props.suiteId!}
        visible={visible}
        setVisible={setVisible}
      />
    </Box>
  );
};
export default Run;
