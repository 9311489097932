export const TestCaseCardsConfig = {
  cardsPerRow: [
    {
      cards: 1,
    },
    {
      minWidth: 980,
      cards: 1,
    },
  ],
};
