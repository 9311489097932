import {
  Button,
  ColumnLayout,
  ExpandableSection,
  FormField,
  Header,
  Multiselect,
  MultiselectProps,
  Select,
  SelectProps,
} from '@amzn/awsui-components-react-v3';
import {
  OptionDefinition,
  OptionGroup,
} from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { RequestError } from '../../../remote/request';
import { createTestsOnDemand } from '../../../remote/testOnDemand';
import {
  CountryOptions,
  DeviceTypeOptions,
  LocaleOptions,
  PageTypeOptions,
  SingleSelectDropdownOptions,
} from '../../ResultsDashboard/FilterSideBar/FiltersConfig';
import { ITestOnDemand, ITestRun } from '../ITestOnDemand';
import { LivingRoomTodRunConfigurationGenerator } from '../LivingRoomTodRunConfigurationGenerator';
import { Country } from '../SignupTodConfig';
import { SignupTodRunConfigurationGenerator } from '../SignupTodRunConfigurationGenerator';
import { DeviceType, hasSignupPages, hasSignupSupport, PageType } from '../TodRunConfigurationGenerator';

interface IConfigurationsContainerProps {}
export interface ITodParams {
  device: DeviceType[];
  page: PageType[];
  country: Country[];
  locale: string[];
}

const TestOnDemandCreate = (props: IConfigurationsContainerProps) => {
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const { showSuccessFlashbar, showInProgressFlashbar, showErrorFlashbar, showWarningFlashbar } = useContext(
    NotificationContext
  );
  const [selectedDevice, setSelectedDevice] = useState<SelectProps.Option>();
  const [selectedCountry, setSelectedCountry] = useState<SelectProps.Option>();
  const [selectedPage, setSelectedPage] = useState<SelectProps.Option>();
  const [selectedLocaleMulti, setSelectedLocaleMulti] = useState<MultiselectProps.Options>([]);
  const queryClient = useQueryClient();
  const createTestOnDemandQuery = useMutation<string[], RequestError, { tod: ITestOnDemand; idToken: string }>(
    'createTestOnDemandQuery',
    (args) => createTestsOnDemand(args.tod, args.idToken),
    {
      onSuccess: (data) => {
        showSuccessFlashbar(`Created Test on Demand with ID: ${data[0]}.`);
        queryClient.invalidateQueries('getTestsOnDemand');
        setSelectedDevice(undefined);
        setSelectedCountry(undefined);
        setSelectedPage(undefined);
        setSelectedLocaleMulti([]);
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not create Test on demand with parameters: [${JSON.stringify(variables)}]. Error occurred: [${
            error.message
          }].`
        );
      },
      onMutate: () => showInProgressFlashbar(`Creating Test On Demand...`),
    }
  );
  const generateTestRuns = (params: ITodParams): ITestRun[] => {
    let testRuns: ITestRun[] = [];
    params.device.forEach((d) => {
      if (hasSignupSupport(d)) {
        if (d === 'livingroom') {
          testRuns = [...testRuns, ...new LivingRoomTodRunConfigurationGenerator().createTestRuns(params)];
        }
      } else {
        if (hasSignupPages(params.page)) {
          testRuns = [...testRuns, ...new SignupTodRunConfigurationGenerator().createTestRuns(params)];
        }
      }
    });
    return testRuns;
  };
  const handleCreateTestOnDemand = () => {
    const devices = selectedDevice!.value as DeviceType;
    const pages = selectedPage!.value as PageType;
    const countries = selectedCountry!.value!.toUpperCase() as Country;
    const locales = selectedLocaleMulti.map((o: OptionDefinition) => {
      return o.value!;
    });
    const query = buildQueryFromParams({
      device: [devices],
      page: [pages],
      country: [countries],
      locale: locales,
    });
    const testRuns = generateTestRuns({
      device: [devices],
      page: [pages],
      country: [countries],
      locale: locales,
    });
    if (testRuns.length > 0) {
      createTestOnDemandQuery.mutate({
        tod: { testSuiteConfiguration: { query: query, queryType: 'ES_LUCENE' }, testRuns: testRuns },
        idToken: idToken,
      });
    } else
      showWarningFlashbar(
        'No test runs generated from selected configuration. Make sure Page exists on chosen Device Type (for example there is no MLP on Livingroom and FireTV).'
      );
  };
  const addPrefixAndOr = (prefix: string, keys: string[]): string => {
    return keys
      .map((d) => {
        return `${prefix}"${d}"`;
      })
      .join(' OR ');
  };
  const buildQueryFromParams = (params: ITodParams): string => {
    const deviceKey = addPrefixAndOr('additional_data.device_type:', params.device);
    const pageKey = addPrefixAndOr('additional_data.page:', params.page);
    const countryKey = addPrefixAndOr('additional_data.country:', params.country);
    const localeKey = addPrefixAndOr('additional_data.locale:', params.locale);
    const queryString = `(${deviceKey}) AND (${pageKey}) AND (${countryKey}) AND (${localeKey})`;
    return queryString;
  };
  const disabledDeviceOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
    { label: 'AppleTVGen45', tags: ['livingroom'], value: 'appletv' },
  ];
  return (
    <ExpandableSection
      data-testid="testondemand-create-content"
      variant="container"
      key="b"
      header={
        <Header
          variant="h2"
          actions={
            <Button
              data-testid="testondemand-create-button-create"
              variant="primary"
              onClick={handleCreateTestOnDemand}
              disabled={
                !selectedDevice ||
                !selectedPage ||
                !selectedCountry ||
                selectedLocaleMulti.length === 0 ||
                createTestOnDemandQuery.isLoading
              }
            >
              Create
            </Button>
          }
        >
          Create test on demand
        </Header>
      }
    >
      <ColumnLayout columns={4}>
        <FormField label="Device Type" data-testid="select-devicetype">
          <Select
            selectedOption={selectedDevice!}
            onChange={({ detail }) => setSelectedDevice(detail.selectedOption)}
            options={(DeviceTypeOptions as (SingleSelectDropdownOptions | OptionDefinition | OptionGroup)[]).map(
              (o) => {
                return JSON.stringify(disabledDeviceOptions).includes(JSON.stringify(o)) ? { ...o, disabled: true } : o;
              }
            )}
            placeholder="Select device"
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField label="Country" data-testid="select-country">
          <Select
            selectedOption={selectedCountry!}
            onChange={({ detail }) => setSelectedCountry(detail.selectedOption)}
            options={CountryOptions}
            placeholder="Select country"
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField label="Page" data-testid="select-page">
          <Select
            selectedOption={selectedPage!}
            onChange={({ detail }) => setSelectedPage(detail.selectedOption)}
            options={PageTypeOptions}
            placeholder="Select page"
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField label="Locale" data-testid="multiselect-locale">
          <Multiselect
            selectedOptions={selectedLocaleMulti}
            onChange={({ detail }) => setSelectedLocaleMulti(detail.selectedOptions)}
            deselectAriaLabel={(e) => 'Remove ' + e.value}
            options={LocaleOptions}
            placeholder="Select locale"
            selectedAriaLabel="Selected"
          />
        </FormField>
      </ColumnLayout>
    </ExpandableSection>
  );
};
export default TestOnDemandCreate;
