import { Box } from '@amzn/awsui-components-react-v3';
import React from 'react';

const EmptyBox = ({ boldText, normalText }: { boldText?: string; normalText?: string }) => {
  return (
    <Box textAlign="center" color="inherit">
      <b>{boldText}</b>
      <Box padding={{ bottom: 's' }} variant="p" color="inherit">
        {normalText}
      </Box>
    </Box>
  );
};

export default EmptyBox;
