import { cardsConfig } from '../SuitesBrowse/CardsConfig';
import { Cards } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { IScreenshot } from '../IScreenshot';
import TestcaseDetailsImage from './TestcaseDetailsImage';
import { TestCaseCardsConfig } from '../TestCaseCardsConfig';

interface TestcaseCardsProps {
  screenshots: IScreenshot[];
  locale?: string;
}

const TestcaseCards = (props: TestcaseCardsProps) => {
  const { items, collectionProps } = useCollection(props.screenshots, {
    filtering: {
      empty: cardsConfig.emptyCard,
      noMatch: cardsConfig.noMatch,
    },
    sorting: {},
  });

  const cardDefinition = {
    sections: [
      {
        id: 'image',
        content: (screenshot: IScreenshot) => <TestcaseDetailsImage screenshotUrl={screenshot.screenshotUrl} />,
      },
    ],
  };

  return (
    <Cards
      {...collectionProps}
      cardDefinition={cardDefinition}
      cardsPerRow={TestCaseCardsConfig.cardsPerRow}
      items={items}
      loadingText="Loading results"
      header={props.locale}
      empty={cardsConfig.emptyCard}
    />
  );
};

export default TestcaseCards;
