import React from 'react';
import ResultDetailsMetadata from '../../ResultsDashboard/Results/ResultDetails/ResultDetailsMetadata/ResultDetailsMetadata';
import { TestResultMetadataMap } from '../../ResultsDashboard/ResultsDashboard';

interface TestcaseDetailsMetadataMapProps {
  testResultMetadataMap: TestResultMetadataMap;
}

const TestcaseDetailsMetadataMap = (props: TestcaseDetailsMetadataMapProps) => {
  const items: { key: string; value: any }[] = [];
  Object.entries(props.testResultMetadataMap).forEach(([key, value]) => {
    if (value) {
      items.push({ key: key.replace('additional_data$', ''), value: value });
    }
  });

  return <ResultDetailsMetadata items={items} />;
};
export default TestcaseDetailsMetadataMap;
