import React, { Dispatch, SetStateAction } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Cards, TextContent } from '@amzn/awsui-components-react-v3';
import { findIntersectionOf, ITestCaseResult } from '../SuiteDataAggregation';
import { Link } from '@reach/router';
import { CardsConfig } from '../../ResultsDashboard/Results/Cards/CardsConfig';
import { cardsConfig } from '../SuitesBrowse/CardsConfig';
import { getStatusIconForStatus } from '../Runs/Run';

interface IScreenshotTestCaseCards {
  testcases: ITestCaseResult[];
  selectedItems: ITestCaseResult[];
  filteredTestcases: ITestCaseResult[];
  visible: boolean;
  setSelectedItems: Dispatch<SetStateAction<ITestCaseResult[]>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ScreenshotTestCaseCards = (props: IScreenshotTestCaseCards) => {
  const cardDefinition = {
    header: (item: ITestCaseResult) => (
      <TextContent>
        <small>
          <Link to={item.testResultId}>{item.testResultMetadataMap.test_result_id}</Link>
        </small>
      </TextContent>
    ),
    sections: [
      {
        id: 'image',
        content: (item: ITestCaseResult) => (
          <Link to={item.testResultId}>
            <img
              className="card-image"
              src={item.screenshots ? (item.screenshots.length > 0 ? item.screenshots[0].screenshotUrl : '') : ''}
              alt={item.testResultMetadataMap.test_result_id!}
            />
          </Link>
        ),
      },
      {
        id: 'freshness',
        content: (item: ITestCaseResult) => (
          <TextContent>
            <small>
              <b>Number of Images: </b>
              {item.screenshots ? item.screenshots!.length : 0}
              <br />
              <b>Submitted: </b>
              {item.testResultMetadataMap!.submit_time!.split('.')[0]}
            </small>
          </TextContent>
        ),
      },
      {
        id: 'testCaseStatus',
        content: (item: ITestCaseResult) => getStatusIconForStatus(item.testcaseStatus!),
      },
    ],
  };

  const { items, collectionProps } = useCollection(findIntersectionOf(props.filteredTestcases, props.testcases), {
    filtering: {},
    sorting: {},
    selection: {},
  });

  return (
    <Cards
      {...collectionProps}
      trackBy="testResultId"
      onSelectionChange={({ detail }) => {
        props.setSelectedItems(detail.selectedItems);
      }}
      selectionType="multi"
      selectedItems={props.selectedItems}
      cardDefinition={cardDefinition}
      cardsPerRow={CardsConfig.cardsPerRow}
      items={items}
      loadingText="Loading results"
      empty={cardsConfig.emptyCard}
    />
  );
};
export default ScreenshotTestCaseCards;
