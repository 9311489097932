import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  ButtonDropdown,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  SplitPanel,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import AppLayoutContext from '../../../hoc/AppLayoutContext';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { getTestsOnDemand } from '../../../remote/testOnDemand';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import { ITestOnDemand } from '../ITestOnDemand';
import {
  addSubmitTimeToQuery,
  getResourceIdsToSubscribe,
  getSplitPanelContent,
  splitPaneli18Strings,
  testResourceTableColumnDefinitions,
  testResourceTablePageSizePreference,
  testResourceTableVisibleContentPreference,
} from '../utils';
interface ITestOnDemandBrowseProps extends RouteComponentProps {
  websocket: () => WebSocket;
}
const TestOnDemandBrowse = (props: ITestOnDemandBrowseProps) => {
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const { showSplitPanel } = useContext(AppLayoutContext);
  const queryClient = useQueryClient();
  const { showErrorFlashbar } = useContext(NotificationContext);
  const [selectedConfigurationRows, setSelectedConfigurationRows] = useState<ITestOnDemand[]>([]);
  const [subscribedIds, setSubscribedIds] = useState<string[]>([]);
  const [websocket, setWebsocket] = useState<WebSocket>();
  const websocketHandler = props.websocket;
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 25,
    visibleContent: ['id', 'createdat', 'devicetype', 'country', 'page', 'locale', 'status'],
  });
  const { data, isLoading, isError, isSuccess } = useQuery(['getTestsOnDemand'], () => getTestsOnDemand(idToken), {
    staleTime: 5 * 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error: Error & { status: number }) => {
      handleStatusCode(error.status);
      showErrorFlashbar(`Could not fetch tests on demand. Error occurred: [${JSON.stringify(error)}]`);
    },
    onSuccess: () => {
      setSelectedConfigurationRows([]);
    },
  });
  const { items, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(data ? data : [], {
    filtering: {},
    sorting: {},
    pagination: { pageSize: preferences.pageSize },
  });
  const [splitPanelContent, setSplitPanelContent] = useState<{ header: string; children: React.ReactNode }>({
    header: `${selectedConfigurationRows.length} Test Resources selected`,
    children: 'Select Test Resource to see its details',
  });
  useEffect(() => {
    showSplitPanel(
      <SplitPanel
        data-testid="testondemand-browse-splitpanel"
        header={splitPanelContent.header}
        children={splitPanelContent.children}
        i18nStrings={splitPaneli18Strings}
      />
    );
  }, [splitPanelContent, showSplitPanel]);

  const subscribeTodIdsToWebSocket = (websocket: WebSocket, todIds: string[]) => {
    if (todIds.length > 0) {
      websocket.send(JSON.stringify({ action: 'onSubscribe', todIds: todIds }));
      setSubscribedIds((s) => [...s, ...todIds]);
    }
  };
  useEffect(() => {
    const configurationStatus = items.map((i) => {
      return i.status;
    });
    if (configurationStatus.includes('IN_PROGRESS') && !websocket) {
      setWebsocket(websocketHandler());
    }
    if (websocket) {
      let toSubscribe: string[] = getResourceIdsToSubscribe([...items], subscribedIds);
      if (websocket.readyState === WebSocket.OPEN) {
        subscribeTodIdsToWebSocket(websocket, toSubscribe);
      }
      websocket.onopen = () => {
        subscribeTodIdsToWebSocket(websocket, toSubscribe);
      };
      websocket.onmessage = (event) => {
        queryClient.invalidateQueries('getTestsOnDemand');
        if (websocket && websocket.readyState === WebSocket.OPEN && !configurationStatus.includes('IN_PROGRESS')) {
          websocket.close();
        }
      };
      websocket.onclose = () => {
        setWebsocket(undefined);
      };
    }
    return () => {
      if (websocket && websocket.readyState === WebSocket.OPEN && !configurationStatus.includes('IN_PROGRESS')) {
        websocket.close();
      }
    };
  }, [items, websocket, subscribedIds, queryClient, websocketHandler]);

  const showError = () => {
    return <GoToHomeButton />;
  };

  const showContent = () => {
    return (
      <Box data-testid="testondemand-browse-content" textAlign="center">
        <Table
          header={
            <Header
              variant="h2"
              description="Status refreshes automatically."
              actions={
                <ButtonDropdown
                  items={[
                    {
                      text: 'Create Suite from selected',
                      id: 'create',
                      disabled:
                        selectedConfigurationRows.length === 0 ||
                        selectedConfigurationRows
                          .map((i) => {
                            return i.status;
                          })
                          .includes('FAILED') ||
                        selectedConfigurationRows
                          .map((i) => {
                            return i.status;
                          })
                          .includes('IN_PROGRESS'),
                    },
                  ]}
                  onItemClick={() => {
                    navigate(`/suites/create`, {
                      state: {
                        queries: selectedConfigurationRows.map((c) => {
                          return {
                            query: addSubmitTimeToQuery(c.createdAt!, c.updatedAt!, c.testSuiteConfiguration.query),
                            queryType: 'ES_LUCENE',
                          };
                        }),
                      },
                    });
                  }}
                >
                  Actions
                </ButtonDropdown>
              }
            >
              Test runs ({items.length})
            </Header>
          }
          {...collectionProps}
          columnDefinitions={testResourceTableColumnDefinitions}
          visibleColumns={preferences.visibleContent}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          empty={
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                <b>No</b> test runs. <br />
              </Box>
            </Box>
          }
          isItemDisabled={(item) => item.testRuns.length === 0}
          selectionType="multi"
          onSelectionChange={({ detail }) => {
            setSelectedConfigurationRows(detail.selectedItems);
            setSplitPanelContent(getSplitPanelContent(detail.selectedItems));
          }}
          selectedItems={selectedConfigurationRows}
          filter={
            <TextFilter
              {...filterProps}
              countText={filteredItemsCount + ' matches.'}
              filteringAriaLabel="Filter test runs"
            />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              pageSizePreference={testResourceTablePageSizePreference}
              visibleContentPreference={testResourceTableVisibleContentPreference}
              onConfirm={({ detail }) => setPreferences(detail)}
            />
          }
          loadingText="Fetching test runs"
          loading={isLoading}
        />
      </Box>
    );
  };

  return (
    <>
      {isError && showError()}
      {(isLoading || isSuccess) && showContent()}
    </>
  );
};
export default TestOnDemandBrowse;
