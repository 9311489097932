export type RequestError = Error & { status: number };
export const request = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const response: Response = await fetch(url, {
    credentials: 'same-origin',
    ...options,
  });

  if (!response.ok) {
    const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
    console.error(errorMsg);
    const error: RequestError = {
      status: response.status,
      ...new Error(errorMsg),
      message: errorMsg,
    };
    throw error;
  }
  const res = await response.text();
  if (response.status === 200 && (options.method === 'GET' || options.method === undefined) && !isJsonString(res)) {
    const errorMsg = `Encountered an error while calling url [${url}]: Received [${response.statusText} ${response.status}] response with empty body.`;
    console.error(errorMsg);
    const error: RequestError = {
      status: response.status,
      ...new Error(),
      message: errorMsg,
    };
    throw error;
  }
  return isJsonString(res) ? JSON.parse(res) : {};
};
const isJsonString = (s: string) => {
  try {
    JSON.parse(s);
  } catch (e) {
    return false;
  }
  return true;
};
