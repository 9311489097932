import React, { createContext } from 'react';

interface IAppLayoutContext {
  setHelpPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode | undefined>>;
  showSplitPanel: (content: React.ReactNode) => void;
  clearSplitPanel: () => void;
  setToolsOpen: any;
}

const AppLayoutContext = createContext<IAppLayoutContext>({
  setHelpPanelContent: () => {},
  setToolsOpen: () => {},
  showSplitPanel: () => {},
  clearSplitPanel: () => {},
});

export default AppLayoutContext;
