//Encrypt a String with SHA256

import { HORAE_METADATA_COUNTRY, HORAE_METADATA_LOCALE } from './ConfigurationUtil';

export const encryptStringWithSHA256 = (str: string) => {
  const PROTOCOL = 'SHA-256';
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return crypto.subtle.digest(PROTOCOL, encodedData);
};

//Convert Hash to Base64-URL
export const hashToBase64url = (arrayBuffer: ArrayBuffer) => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
  const decodedHash = btoa(stringifiedArrayHash);

  const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  return base64URL;
};

//Generate a Random String
export const getRandomString = () => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map((dec) => (dec / 100) % 100);
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
};

export const getBreadcrumbItemsFromLocation = (location: string) => {
  return location
    .split('/')
    .slice(2)
    .map((item) => {
      return { text: item.charAt(0).toUpperCase() + item.slice(1), href: location.split(item)[0] + item };
    });
};

export const getUTCDateString = (d: string) => {
  return new Date(d).toISOString();
};

export const getLocalDateString = (d: string) => {
  return new Date(d).toDateString();
};

export const getDeviceFromQueryString = (query: string): string => {
  const optionalDevice = query.split('additional_data.device_type:');
  return optionalDevice ? (optionalDevice.length === 2 ? optionalDevice[1].split(' ')[0] : '') : '';
};

export const getCountryFromQueryString = (query: string): string => {
  const optionalCountry = query.split('additional_data.country:');
  if (optionalCountry && optionalCountry.length === 2) {
    return optionalCountry[1].split(' ')[0];
  } else {
    const optionalHoraeMetadataCountry = query.split(HORAE_METADATA_COUNTRY);
    return optionalHoraeMetadataCountry
      ? optionalHoraeMetadataCountry.length === 2
        ? optionalHoraeMetadataCountry[1].split(' ')[0]
        : ''
      : '';
  }
};

export const getPageFromQueryString = (query: string): string => {
  const optionalPage = query.split('additional_data.page:');
  return optionalPage && optionalPage.length === 2 ? (optionalPage[1] ? optionalPage[1].split(' ')[0] : '') : '';
};

export const getLocaleFromQueryString = (query: string): string => {
  const optionalLocale = query.split('additional_data.locale:');
  if (optionalLocale && optionalLocale.length === 2) {
    return removeQuotes(optionalLocale[1].split(' ')[0]);
  } else {
    const optionalHoraeMetadataLocale = query.split(HORAE_METADATA_LOCALE);
    return removeQuotes(
      optionalHoraeMetadataLocale
        ? optionalHoraeMetadataLocale.length === 2
          ? optionalHoraeMetadataLocale[1].split(' ')[0]
          : ''
        : ''
    );
  }
};

const removeQuotes = (text: string): string => {
  return text.replace(/['"]+/g, '');
};
