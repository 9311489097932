import { Button, Checkbox, SpaceBetween, TextFilter } from '@amzn/awsui-components-react-v3';
import React, { Dispatch, SetStateAction } from 'react';
import { ITestCaseFilter, ITestCaseResult } from '../SuiteDataAggregation';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface ICommonTestCaseFilters {
  testcases: ITestCaseResult[];
  selectedItems: ITestCaseResult[];
  checkBoxFilters: ITestCaseFilter;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setCheckBoxFilters: Dispatch<SetStateAction<ITestCaseFilter>>;
  setTextFilter: Dispatch<SetStateAction<string>>;
  setExpandNoScreenshotSection: Dispatch<SetStateAction<boolean>>;
}

const CommonTestCaseFilters = (props: ICommonTestCaseFilters) => {
  const { filterProps, filteredItemsCount } = useCollection(props.testcases, {
    filtering: {},
    sorting: {},
    selection: {},
  });
  const handleCheckBox = (filter: ITestCaseFilter): void => {
    props.setCheckBoxFilters(filter);
    props.setExpandNoScreenshotSection(true);
  };

  return (
    <SpaceBetween size="s">
      <TextFilter
        {...filterProps}
        onChange={(e) => {
          props.setTextFilter(e.detail.filteringText);
          props.setExpandNoScreenshotSection(true);
          filterProps.onChange(e);
        }}
        countText={filteredItemsCount + ' matches.'}
        filteringPlaceholder="Filter test cases"
      />
      <SpaceBetween direction="horizontal" size="m">
        <Checkbox
          checked={props.checkBoxFilters.passed}
          onChange={() => handleCheckBox({ ...props.checkBoxFilters, passed: !props.checkBoxFilters.passed })}
        >
          Passed
        </Checkbox>
        <Checkbox
          checked={props.checkBoxFilters.failed}
          onChange={() => handleCheckBox({ ...props.checkBoxFilters, failed: !props.checkBoxFilters.failed })}
        >
          Failed
        </Checkbox>
        <Checkbox
          checked={props.checkBoxFilters.blocked}
          onChange={() => handleCheckBox({ ...props.checkBoxFilters, blocked: !props.checkBoxFilters.blocked })}
        >
          Blocked
        </Checkbox>
        <Checkbox
          checked={props.checkBoxFilters.untested}
          onChange={() => handleCheckBox({ ...props.checkBoxFilters, untested: !props.checkBoxFilters.untested })}
        >
          Untested
        </Checkbox>
      </SpaceBetween>

      <Button disabled={props.selectedItems.length === 0} onClick={() => props.setVisible(true)}>
        Refetch test cases
      </Button>
    </SpaceBetween>
  );
};
export default CommonTestCaseFilters;
