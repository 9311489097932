import { ITestCaseResult } from '../components/Suites/SuiteDataAggregation';

export const getAdditionalDataLocale = (testcaseResult: ITestCaseResult): string => {
  return testcaseResult
    ? testcaseResult!.testResultMetadataMap
      ? testcaseResult!.testResultMetadataMap!.additional_data$locale
        ? testcaseResult!.testResultMetadataMap!.additional_data$locale!
        : ''
      : ''
    : '';
};
