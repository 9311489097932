import { TRSResult } from '../ResultsDashboard/ResultsDashboard';
import { IConfiguration } from './IConfiguration';
import { ISuite } from './ISuite';
import { ConfigurationStatus } from './ConfigurationStatus';

export interface ITestCaseResult extends TRSResult {
  testcaseStatus?: ITestCaseStatus;
}

export interface ITestCaseGroupByScreenshot {
  screenshotTestResults?: ITestCaseResult[];
  noScreenshotTestResults?: ITestCaseResult[];
}

export interface ITestCaseFilter {
  passed: boolean;
  failed: boolean;
  blocked: boolean;
  untested: boolean;
}

export type ITestCaseStatus = 'PASSED' | 'FAILED' | 'BLOCKED' | 'UNTESTED';

export type StatusToBooleanRecord = Record<ITestCaseStatus, boolean>;

export const filterImageTestcases = (testcases: ITestCaseResult[]): ITestCaseResult[] => {
  return testcases.filter((testcase) => testcase.screenshots !== undefined && testcase.screenshots.length > 0);
};

export const getTestCaseStatusFromConfiguration = (c: IConfiguration) => {
  let passed = 0;
  let failed = 0;
  let blocked = 0;
  let untested = 0;
  if (c.metadata && c.metadata.analytics) {
    passed = c.metadata.analytics.passedTestcases;
    failed = c.metadata.analytics.failedTestcases;
    blocked = c.metadata.analytics.blockedTestcases;
    untested = c.metadata.analytics.untestedTestcases;
  }
  return [passed, failed, blocked, untested];
};

export const getNumberOfNotStartedConfigurations = (suite: ISuite): number => {
  let count = 0;
  suite.configurations.forEach((configuration) => {
    if (getTotalNumberOfTestCasesFromConfiguration(configuration) === 0) {
      count++;
    }
  });
  return count;
};

export const getTotalNumberOfTestCasesFromTestCaseResults = (testResults: ITestCaseGroupByScreenshot) => {
  return testResults ? testResults.screenshotTestResults!.length + testResults.noScreenshotTestResults!.length : 0;
};

export const isQueryDone = (configuration: IConfiguration) => {
  return configuration.metadata ? configuration.metadata.queryStatus === ConfigurationStatus.DONE : false;
};

export const isQueryInProgress = (configuration: IConfiguration) => {
  return configuration.metadata ? configuration.metadata.queryStatus === ConfigurationStatus.IN_PROGRESS : false;
};

export const isQueryFailed = (configuration: IConfiguration) => {
  return configuration.metadata ? configuration.metadata.queryStatus === ConfigurationStatus.FAILED : false;
};

export const getTotalNumberOfTestCasesFromConfiguration = (configuration: IConfiguration) => {
  return configuration.metadata
    ? configuration.metadata.analytics
      ? configuration.metadata.analytics.totalTestcases
      : 0
    : 0;
};

export const getTotalNumberOfTestCasesFromSuite = (suite: ISuite): number => {
  let total = 0;

  suite.configurations.forEach((configuration) => {
    total = total + getTotalNumberOfTestCasesFromConfiguration(configuration);
  });

  return total;
};

export const getTestCaseStatusFromSuite = (suite: ISuite): number[] => {
  let passed = 0;
  let failed = 0;
  let blocked = 0;
  let untested = 0;

  suite.configurations.forEach((configuration) => {
    let [p, f, b, u] = getTestCaseStatusFromConfiguration(configuration);
    passed = passed + p;
    failed = failed + f;
    blocked = blocked + b;
    untested = untested + u;
  });

  return [passed, failed, blocked, untested];
};

export const calculatePercentage = (value: number, total: number) => {
  return total === 0 ? 0 : (value / total) * 100;
};

export const calculateSuiteProgress = (suite: ISuite) => {
  if (getTotalNumberOfTestCasesFromSuite(suite) > 0) {
    let [p, f, b] = getTestCaseStatusFromSuite(suite);
    return ((p + f + b) / getTotalNumberOfTestCasesFromSuite(suite)) * 100;
  } else {
    return 0;
  }
};

export const findIntersectionOf = (
  testResults1: ITestCaseResult[],
  testResults2: ITestCaseResult[]
): ITestCaseResult[] => {
  return testResults1.filter((testcase) => testResults2.includes(testcase));
};
export const filterByTestResultId = (testResults: ITestCaseResult[], targetId: string): ITestCaseResult[] => {
  return testResults.filter((i) => i.testResultId.toLowerCase() === targetId.toLowerCase());
};

export const filterTestResultsByTestCaseStatus = (
  testResults: ITestCaseResult[],
  filters: ITestCaseFilter
): ITestCaseResult[] => {
  return testResults.filter((testCaseResult: ITestCaseResult) => {
    if (!filters.passed && !filters.failed && !filters.blocked && !filters.untested) {
      return true;
    }
    const statusToFilter: StatusToBooleanRecord = {
      BLOCKED: filters.blocked,
      FAILED: filters.failed,
      PASSED: filters.passed,
      UNTESTED: filters.untested,
    };
    return statusToFilter[testCaseResult.testcaseStatus ?? 'UNTESTED'] ?? true;
  });
};

export const removeTestResultsByTestResultId = (
  testResults: ITestCaseResult[],
  toRemove: ITestCaseResult[]
): ITestCaseResult[] => {
  return testResults.filter((testCaseResult: ITestCaseResult) => {
    return !toRemove.includes(testCaseResult);
  });
};

export const getConfigurationFromSuite = (configurationId: string, suite: ISuite): IConfiguration => {
  return suite.configurations.filter((configuration) => {
    return configuration.id === configurationId;
  })[0];
};

export const getSubmitTimeDifferenceInHours = (submitTime: string, dateNow: number) => {
  const timeDiffInHours = (dateNow - new Date(submitTime).getTime()).valueOf() / 1000 / 3600;
  const timeDiffInDays = timeDiffInHours / 24;
  return timeDiffInHours < 24 ? `${Math.trunc(timeDiffInHours)} hours` : `${Math.ceil(timeDiffInDays)} days`;
};

export const getTestCaseIdsFromTestResults = (testResults: ITestCaseResult[]) => {
  return testResults.map((testResult) => testResult.testResultId);
};
