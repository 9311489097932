import { Box, Spinner } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { RequestError } from '../../../remote/request';
import { getSuiteById } from '../../../remote/suites';
import { buildDefaultConfigs, getQueries } from '../../../util/ConfigurationUtil';
import { getBreadcrumbItemsFromLocation } from '../../../util/stringutil';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import SuitesCreate from './SuitesCreate';

interface ISuitesCloneProps {
  suiteId?: string;
}

const SuitesClone = (props: ISuitesCloneProps & RouteComponentProps) => {
  const { showErrorFlashbar } = useContext(NotificationContext);
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const location = useLocation();
  const { data, isLoading, isError, isSuccess } = useQuery(
    'getSuiteById',
    () => getSuiteById(props.suiteId!, idToken, true, false),
    {
      retry: false,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        showErrorFlashbar(`Failed to fetch suite with id [${props.suiteId}]. Error: [${error.message}]`);
      },
    }
  );
  const showError = () => {
    return <GoToHomeButton />;
  };

  const showSpinner = () => {
    return <Spinner size="large" />;
  };

  const showContent = () => {
    return (
      <SuitesCreate
        breadcrumbs={getBreadcrumbItemsFromLocation(location.pathname)}
        configurations={buildDefaultConfigs(data!.configurations, true)}
        queries={getQueries(data!.configurations, true)}
      />
    );
  };

  return (
    <Box textAlign="center">
      {isError && showError()}
      {isLoading && showSpinner()}
      {isSuccess && showContent()}
    </Box>
  );
};
export default SuitesClone;
