import { Box } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import GoToHomeButton from '../Help/Home/GoToHomeButton';

/**
 * Having a redirect if can't find the page
 * @returns
 */
const NotFoundPage = (props: RouteComponentProps): React.ReactElement => {
  return (
    <Box textAlign="center" color="inherit">
      <h1>Four Oh Four</h1>
      <Box padding={{ bottom: 'l' }} variant="p" color="inherit">
        Looks like you entered uncharted waters. Please double check your URL.
      </Box>
      <GoToHomeButton />
    </Box>
  );
};

export default NotFoundPage;
