import { Box, Button, Container, Grid, Header, Icon, SpaceBetween, TextContent } from '@amzn/awsui-components-react-v3';
import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import './Home.css';

const Home = (props: RouteComponentProps) => {
  return (
    <Box padding="xxl">
      <Grid
        gridDefinition={[
          { colspan: 4, offset: 1 },
          { colspan: 4, offset: 1 },
        ]}
      >
        <Container className="home-card" header={<Header>Journeys</Header>}>
          <Box padding="l">
            <SpaceBetween direction="vertical" size="xxl">
              <Box textAlign="center">
                <Icon name="folder-open" size="large" variant="normal" />
              </Box>
              <TextContent className="text-content">
                <b>View</b> current Prime Video experience screenshots.
              </TextContent>
              <Box textAlign="center">
                <Link to="/journeys/browse">
                  <Button className="card-cta">Browse</Button>
                </Link>
              </Box>
            </SpaceBetween>
          </Box>
        </Container>
        <Container className="home-card" header={<Header>Suites</Header>}>
          <Box padding="l">
            <SpaceBetween direction="vertical" size="xxl">
              <Box textAlign="center">
                <Icon name="bug" size="large" variant="normal" />
              </Box>
              <TextContent className="text-content">
                <b>Create</b>, <b>browse</b> and <b>run</b> Test Suites.
              </TextContent>
              <Box textAlign="center">
                <SpaceBetween size="s">
                  <Link to="/suites/browse">
                    <Button className="card-cta">Browse</Button>
                  </Link>
                  <Link to="/suites/create">
                    <Button className="card-cta">Create</Button>
                  </Link>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </Box>
        </Container>
      </Grid>
    </Box>
  );
};

export default Home;
