import { Box } from '@amzn/awsui-components-react-v3';
import React from 'react';
import BadgeGroup from './BadgeGroup';
import { IConfiguration } from '../IConfiguration';
import { isDefault } from '../../../util/ConfigurationUtil';
import ConfigurationCardQueryTitleTextContent from './ConfigurationCardQueryTitleTextContent';

interface IConfigurationCardTitleProps {
  configuration: IConfiguration;
}

const ConfigurationCardTitle = (props: IConfigurationCardTitleProps) => {
  return isDefault(props.configuration) ? (
    <BadgeGroup configuration={props.configuration} />
  ) : (
    <Box variant="h3" color="inherit">
      <ConfigurationCardQueryTitleTextContent
        title={props.configuration.queryTitle}
        query={props.configuration.query}
      />
    </Box>
  );
};

export default ConfigurationCardTitle;
