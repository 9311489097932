import { IConfiguration } from '../components/Suites/IConfiguration';
import {
  getCountryFromQueryString,
  getDeviceFromQueryString,
  getLocaleFromQueryString,
  getPageFromQueryString,
} from './stringutil';
import { IQuery } from '../components/Suites/IQuery';
import {
  getTestCaseStatusFromConfiguration,
  getTotalNumberOfTestCasesFromConfiguration,
} from '../components/Suites/SuiteDataAggregation';
import { QueryType } from '../components/Suites/QueryType';

const ADDITIONAL_DATA_COUNTRY: string = 'additional_data.country:';
const ADDITIONAL_DATA_LOCALE: string = 'additional_data.locale:"';

const androidClientPages: Set<string> = new Set(['details', 'settings', 'search', 'storefront', 'mystuff']);

const isAndroidClient = (config: IConfiguration): boolean => {
  return config.device!.toLowerCase() === 'android' && androidClientPages.has(config.page!.toLowerCase());
};

export const HORAE_METADATA_LOCALE: string = 'horae_metadata.execution_command.test_identifier:"-Elocale=';
export const HORAE_METADATA_COUNTRY: string = 'horae_metadata.execution_command.ip_spoof_territory:';

export const isDefault = (configuration: IConfiguration) => {
  return configuration.metadata ? configuration.metadata!.isDefault : true;
};

export const filterDefaultConfigs = (configurations: IConfiguration[]): IConfiguration[] => {
  return configurations.filter((config) => isDefault(config) || config.country);
};

export const filterQueryConfigs = (configurations: IConfiguration[]): IConfiguration[] => {
  return configurations.filter((config) => !isDefault(config));
};

export const buildDefaultConfigs = (configurations: IConfiguration[], reset: boolean): IConfiguration[] => {
  const defaultConfigs: IConfiguration[] = filterDefaultConfigs(configurations).map((config) => {
    if (config.country) {
      return config;
    }
    return {
      country: getCountryFromQueryString(config.query!),
      device: getDeviceFromQueryString(config.query!),
      page: getPageFromQueryString(config.query!),
      locale: getLocaleFromQueryString(config.query!),
      metadata: reset ? { isDefault: config.metadata ? config.metadata!.isDefault : true } : config.metadata,
      id: reset ? undefined : config.id,
      testResults: reset ? {} : config.testResults,
    };
  });
  return defaultConfigs ? defaultConfigs : [];
};

export const getQueries = (configurations: IConfiguration[], reset: boolean): IQuery[] => {
  const queries: IQuery[] = filterQueryConfigs(configurations).map((config) => {
    return {
      query: config.query!,
      queryType: config.queryType!,
      queryTitle: config.queryTitle,
      metadata: reset
        ? {
            isDefault: config.metadata ? config.metadata!.isDefault : true,
          }
        : config.metadata,
      id: reset ? undefined : config.id,
      testResults: reset ? {} : config.testResults,
    };
  });
  return queries ? queries : [];
};

export const getConfigurationsStatus = (
  configurations: IConfiguration[]
): [IConfiguration[], IConfiguration[], IConfiguration[]] => {
  let inProgress: IConfiguration[] = [];
  let notStarted: IConfiguration[] = [];
  let completed: IConfiguration[] = [];
  configurations.forEach((configuration) => {
    let untested = getTestCaseStatusFromConfiguration(configuration)[3];
    if (getTotalNumberOfTestCasesFromConfiguration(configuration) === 0) {
      notStarted.push(configuration);
    } else if (untested === 0) {
      completed.push(configuration);
    } else {
      inProgress.push(configuration);
    }
  });
  return [inProgress, notStarted, completed];
};

export const convertConfigurationsToQueries = (configurations: IConfiguration[]): IQuery[] => {
  return configurations
    .filter((config) => !config.query && config.country)
    .map((config) => {
      const query = `test_start_time_utc_host:[now-8d/d TO now] AND test_team:"pv-international-qa" AND additional_data.page:${
        config.page
      } AND additional_data.device_type:${config.device} AND ${
        isAndroidClient(config) ? HORAE_METADATA_LOCALE : ADDITIONAL_DATA_LOCALE
      }${config.locale}" AND ${isAndroidClient(config) ? HORAE_METADATA_COUNTRY : ADDITIONAL_DATA_COUNTRY}${
        config.country
      }`;
      return {
        query: query,
        queryType: QueryType.ES_LUCENE,
        metadata: {
          isDefault: true,
          analytics: config.metadata ? config.metadata.analytics : undefined,
        },
        id: config.id,
        testResults: config.testResults,
      };
    });
};
