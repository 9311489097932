import { HelpPanel, Icon } from '@amzn/awsui-components-react-v3';
import React from 'react';

const EmptyHelpPanelContent = () => {
  return (
    <HelpPanel header={<h2>Help</h2>}>
      <div>
        <p>
          This is a <b>Help panel</b>.
        </p>
        <p>
          Click on the <Icon variant="normal" name="status-info" /> icons found on various sections to get relevant
          help.
        </p>
        <p>
          Click on <Icon variant="normal" name="close" /> to close this panel.
        </p>
      </div>
    </HelpPanel>
  );
};
export default EmptyHelpPanelContent;
