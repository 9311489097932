import { AppLayout, Flashbar, SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react-v3';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AppLayoutContext from '../../hoc/AppLayoutContext';
import { NotificationContext } from '../../hoc/NotificationContext';
import EmptyHelpPanelContent from '../Help/EmptyHelpPanelContent';
import Routes from './Routes';

interface ILayoutProps {}

const Layout = (props: RouteComponentProps<ILayoutProps>) => {
  const { flashbarItems } = useContext(NotificationContext);
  const [helpPanelContent, setHelpPanelContent] = useState<React.ReactNode>();
  const [splitPanelContent, setSplitPanelContent] = useState<React.ReactNode>();
  const [activeHref, setActiveHref] = useState('browse');
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const splitPanelHandler = useCallback((content: React.ReactNode) => {
    setSplitPanelContent(content);
  }, []);
  const clearSplitPanel = () => {
    setSplitPanelContent(undefined);
    setSplitPanelOpen(false);
  };
  const toolsChangeHandler = () => {
    setToolsOpen(!toolsOpen);
    setHelpPanelContent(<EmptyHelpPanelContent />);
  };
  const navigationChangeHandler = () => {
    setNavigationOpen(!navigationOpen);
  };

  const sideNavigationFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      setActiveHref(event.detail.href);
      navigate(event.detail.href);
    }
  };

  //Disables Split Panel outside the Test On Demand page
  useEffect(() => {
    if (!window.location.pathname.includes('testondemand')) clearSplitPanel();
  }, [activeHref]);

  const sideNavigationConfig: {
    items: SideNavigationProps.Item[];
    header: SideNavigationProps.Header;
  } = {
    items: [
      {
        type: 'section',
        text: 'Journeys',
        items: [{ type: 'link', text: 'Browse', href: '/journeys/browse' }],
      },
      {
        type: 'section',
        text: 'Suites',
        items: [
          { type: 'link', text: 'Browse', href: '/suites/browse' },
          { type: 'link', text: 'Create', href: '/suites/create' },
        ],
      },
      {
        type: 'section',
        text: 'Test on Demand',
        items: [{ type: 'link', text: 'Browse', href: '/testondemand/browse' }],
      },
    ],
    header: { href: '/', text: 'Home' },
  };

  return (
    <AppLayoutContext.Provider
      value={{
        setHelpPanelContent: setHelpPanelContent,
        setToolsOpen: setToolsOpen,
        showSplitPanel: splitPanelHandler,
        clearSplitPanel: clearSplitPanel,
      }}
    >
      <AppLayout
        headerSelector="Header"
        contentType="default"
        maxContentWidth={1920}
        disableContentPaddings={true}
        splitPanel={splitPanelContent}
        navigation={
          <SideNavigation
            activeHref={activeHref}
            onFollow={sideNavigationFollowHandler}
            header={sideNavigationConfig.header}
            items={sideNavigationConfig.items}
          />
        }
        navigationWidth={180}
        navigationOpen={navigationOpen}
        onNavigationChange={navigationChangeHandler}
        notifications={<Flashbar items={flashbarItems} />}
        tools={helpPanelContent}
        toolsOpen={toolsOpen}
        stickyNotifications={true}
        onToolsChange={toolsChangeHandler}
        content={<Routes />}
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={() => {
          setSplitPanelOpen(!splitPanelOpen);
        }}
      />
    </AppLayoutContext.Provider>
  );
};
export default Layout;
