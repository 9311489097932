import fetch from "node-fetch";
import NodeRSA from "node-rsa";
import Crypto from "crypto";
import { cognitoConfig } from '../util/cognitoconfig';

function decodeAndJsonParse<T>(base64: string): T {
  const json = new Buffer(base64, "base64").toString("ascii");
  return JSON.parse(json);
}

export async function isTokenValid(jwt: string) {
  const [rawHead, rawBody, signature] = jwt.split(".");

  const parsedHead = decodeAndJsonParse<{ alg: string; kid: string }>(rawHead);
  if (parsedHead.alg !== "RS256") {
    return false;
  }

  const jwksResponse = await fetch(cognitoConfig.jwksEndpoint);
  const jwks: JWKS = (await jwksResponse.json()) as JWKS;

  const jwk = jwks.keys.find((key) => key.kid === parsedHead.kid);

  if (!jwk) {
    return false;
  }

  if (jwk.alg !== "RS256") {
    return false;
  }

  const key = new NodeRSA();
  key.importKey(
    {
      n: Buffer.from(jwk.n, "base64"),
      e: Buffer.from(jwk.e, "base64"),
    },
    "components-public"
  );

  const pem = key.exportKey("pkcs8-public-pem");
  const verifyObject = Crypto.createVerify("RSA-SHA256");
  verifyObject.write(rawHead + "." + rawBody);
  verifyObject.end();

  const decodedSignature = Buffer.from(signature, "base64").toString("base64");
  const signatureIsValid = verifyObject.verify(pem, decodedSignature, "base64");

  return signatureIsValid;
}

type JWKS = {
  keys: JWK[];
};

type JWK = {
  alg: string;
  kty: string;
  use: string;
  n: string;
  e: string;
  kid: string;
  x5t: string;
  x5c: string[];
};