import validate from 'uuid-validate';
import { IConfiguration } from '../components/Suites/IConfiguration';
import { ISuite } from '../components/Suites/ISuite';
import {
  ITestCaseResult,
  ITestCaseGroupByScreenshot,
  ITestCaseStatus,
} from '../components/Suites/SuiteDataAggregation';
import { request } from './request';

export interface ICreateSuiteRequest {
  testSuites: ISuite[];
}
export interface IGetSuitesRequest {
  testSuites: ISuite[];
  metadata: { continuationToken: string };
}
export interface IRefetchTestCasesOptions {
  idToken: string;
  suiteId: string;
  configurationId: string;
  testResultIds: string[];
}

export const getSuites = (idToken: string, page = 1, continuationToken?: string): Promise<IGetSuitesRequest> => {
  return request<IGetSuitesRequest>(
    `/testSuites?pageSize=25&pageNumber=${page}${continuationToken ? `&continuationToken=${continuationToken}` : ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const createSuite = (suite: ICreateSuiteRequest, idToken: string): Promise<{}> => {
  return request('/testSuites/', {
    method: 'POST',
    body: JSON.stringify(suite),
    headers: {
      Authorization: idToken,
    },
  });
};

export const editSuite = (newSuite: ISuite, idToken: string): Promise<{}> => {
  return request('/testSuites/', {
    method: 'POST',
    body: JSON.stringify(newSuite),
    headers: {
      Authorization: idToken,
    },
  });
};

export const deleteConfigurationsFromSuite = (suiteId: string, idToken: string, configurationIds: string[]) => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  return request<null>(`/testSuites/${suiteId}/configurations`, {
    method: 'DELETE',
    body: JSON.stringify({ configurationIds: configurationIds }),
    headers: {
      Authorization: idToken,
    },
  });
};
export const addConfigurationsToSuite = (suiteId: string, idToken: string, configurations: IConfiguration[]) => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  return request<{ configurationIds: string[] }>(`/testSuites/${suiteId}/configurations`, {
    method: 'POST',
    body: JSON.stringify(configurations),
    headers: {
      Authorization: idToken,
    },
  });
};
export const updateGeneralSettingsInSuite = (
  suiteId: string,
  idToken: string,
  newSuite: { neededBy?: string; note?: string; title?: string }
) => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  return request<null>(`/testSuites/${suiteId}`, {
    method: 'PATCH',
    body: JSON.stringify(newSuite),
    headers: {
      Authorization: idToken,
    },
  });
};

export const getSuiteById = (
  suiteId: string,
  idToken: string,
  hideTestcases: boolean,
  computeAnalytics: boolean
): Promise<ISuite> => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  return request(
    `/testSuites/${suiteId}${hideTestcases ? '?hideTestcases=true' : '?hideTestcases=false'}${
      computeAnalytics ? '&computeAnalytics=true' : '&computeAnalytics=false'
    }`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const getTestCaseById = (
  idToken: string,
  suiteId: string,
  configurationId: string,
  testCaseId: string,
  withLocale?: string
): Promise<ITestCaseResult> => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  if (!validate(configurationId)) {
    console.error(`Invalid configuration id.`);
    throw new Error('Invalid configuration id.');
  }
  if (!validate(testCaseId)) {
    console.error(`Invalid test case id.`);
    throw new Error('Invalid test case id.');
  }
  return request(
    `/testSuites/${suiteId}/configurations/${configurationId}/testcases/${testCaseId}${
      withLocale ? `?withLocale=${withLocale}` : ''
    }`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const setTestCaseStatus = (
  idToken: string,
  suiteId: string,
  configurationId: string,
  testCaseId: string,
  testCaseStatus: ITestCaseStatus
): Promise<ITestCaseResult> => {
  return request(`/testSuites/${suiteId}/configurations/${configurationId}/testcases/${testCaseId}`, {
    method: 'PUT',
    body: JSON.stringify({ testcaseStatus: testCaseStatus }),
    headers: {
      Authorization: idToken,
    },
  });
};

export const getConfigurationById = (
  idToken: string,
  suiteId: string,
  configurationId: string,
  hideTestcases: boolean,
  computeAnalytics: boolean
) => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  if (!validate(configurationId)) {
    console.error(`Invalid configuration id.`);
    throw new Error('Invalid configuration id.');
  }
  return request(
    `/testSuites/${suiteId}/configurations/${configurationId}${
      hideTestcases ? '?hideTestcases=true' : '?hideTestcases=false'
    }${computeAnalytics ? '&computeAnalytics=true' : '&computeAnalytics=false'}`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const getTestcases = (
  idToken: string,
  suiteId: string,
  configurationId: string,
  urlSearchParams?: URLSearchParams
) => {
  if (!validate(suiteId)) {
    console.error(`Invalid suite id.`);
    throw new Error('Invalid suite id.');
  }
  if (!validate(configurationId)) {
    console.error(`Invalid configuration id.`);
    throw new Error('Invalid configuration id.');
  }
  return request<{
    testResults: ITestCaseGroupByScreenshot;
  }>(
    `/testSuites/${suiteId}/configurations/${configurationId}/testcases${
      urlSearchParams && `?${urlSearchParams.toString()}`
    }`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const postTestcases = (idToken: string, suiteId: string, configurationId: string): Promise<any> => {
  return request(`/testSuites/${suiteId}/configurations/${configurationId}/testcases`, {
    method: 'POST',
    headers: {
      Authorization: idToken,
      InvocationType: 'Event',
      'Content-Type': 'application/json',
    },
  });
};

export const refetchTestCases = ({ idToken, suiteId, configurationId, testResultIds }: IRefetchTestCasesOptions) => {
  return request<{
    testResults: ITestCaseResult[];
  }>(`/testSuites/${suiteId}/configurations/${configurationId}/testcases`, {
    method: 'PUT',
    headers: {
      Authorization: idToken,
    },
    body: JSON.stringify({ testResultIds: testResultIds }),
  });
};
