export const CardsConfig = {
  cardsPerRow: [
    {
      cards: 4,
    },
    {
      minWidth: 980,
      cards: 5,
    },
  ],
  features: ['filtering'],
  cardsPageSizeSelectorOptions: [
    { value: 18, label: '18 items' },
    { value: 36, label: '36 items' },
    { value: 72, label: '72 items' },
  ],
};
