import { createContext } from 'react';

interface IAuthorizationContext {
  idToken: string;
  setIdToken: (arg: string) => void;
  isAuthorized: boolean;
  setIsAuthorized: (arg: boolean) => void;
  handleStatusCode: (code: number) => void;
}

const AuthorizationContext = createContext<IAuthorizationContext>({
  idToken: '',
  setIdToken: () => {},
  isAuthorized: false,
  setIsAuthorized: () => {},
  handleStatusCode: () => {},
});

export default AuthorizationContext;
