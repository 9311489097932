import { Button } from '@amzn/awsui-components-react-v3';
import React, { useContext } from 'react';
import AppLayoutContext from '../../hoc/AppLayoutContext';

const HelpPanelInfoIcon = (props: { content: JSX.Element }) => {
  const { setHelpPanelContent, setToolsOpen } = useContext(AppLayoutContext);

  return (
    <Button
      variant="icon"
      iconName="status-info"
      onClick={() => {
        setToolsOpen(true);
        setHelpPanelContent(props.content);
      }}
    >
      Info
    </Button>
  );
};
export default HelpPanelInfoIcon;
