import { Badge, Box, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { IConfiguration } from '../IConfiguration';
import './Suite.css';
import {
  getCountryFromQueryString,
  getDeviceFromQueryString,
  getLocaleFromQueryString,
  getPageFromQueryString,
} from '../../../util/stringutil';

interface IBadgeGroupProps {
  configuration: IConfiguration;
}

const BadgeGroup = (props: IBadgeGroupProps) => {
  return (
    <SpaceBetween size="xl" direction="horizontal">
      <Badge className="badge-device">
        <Box variant="h3" color="inherit">
          {props.configuration.device
            ? props.configuration.device
            : getDeviceFromQueryString(props.configuration.query!)}
        </Box>
      </Badge>
      <Badge className="badge-country">
        <Box variant="h3" color="inherit">
          {props.configuration.country
            ? props.configuration.country
            : getCountryFromQueryString(props.configuration.query!)}
        </Box>
      </Badge>
      <Badge className="badge-page">
        <Box variant="h3" color="inherit">
          {props.configuration.page ? props.configuration.page : getPageFromQueryString(props.configuration.query!)}
        </Box>
      </Badge>
      <Badge className="badge-locale">
        <Box variant="h3" color="inherit">
          {props.configuration.locale
            ? props.configuration.locale
            : getLocaleFromQueryString(props.configuration.query!)}
        </Box>
      </Badge>
    </SpaceBetween>
  );
};

export default BadgeGroup;
