import React, { Dispatch, SetStateAction, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  TableProps,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { findIntersectionOf, ITestCaseResult } from '../SuiteDataAggregation';
import { Link } from '@reach/router';
import { getStatusIconForStatus } from '../Runs/Run';

interface INoScreenshotTestCaseTable {
  testcases: ITestCaseResult[];
  suiteId: string;
  configurationId: string;
  visible: boolean;
  selectedItems: ITestCaseResult[];
  filteredTestcases: ITestCaseResult[];
  setVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedItems: Dispatch<SetStateAction<ITestCaseResult[]>>;
}

const testNameSorter = (item1: ITestCaseResult, item2: ITestCaseResult): number => {
  return item2.testResultMetadataMap['test_name']!.localeCompare(item1.testResultMetadataMap['test_name']!);
};

const testCaseIdSorter = (item1: ITestCaseResult, item2: ITestCaseResult): number => {
  return item2.testResultMetadataMap['test_case_id']!.localeCompare(item1.testResultMetadataMap['test_case_id']!);
};

const testcaseStatusSorter = (item1: ITestCaseResult, item2: ITestCaseResult): number => {
  return (item2.testcaseStatus ?? 'UNTESTED'!).localeCompare(item1.testcaseStatus ?? 'UNTESTED'!);
};

const NoScreenshotTestCaseTable = (props: INoScreenshotTestCaseTable) => {
  const noScreenshotTestCaseTableColumnDefinitions: TableProps.ColumnDefinition<ITestCaseResult>[] = [
    {
      id: 'id',
      header: 'Test Result ID',
      cell: (item) => (
        <TextContent>
          <small>
            <Link to={item.testResultId}>{item.testResultMetadataMap.test_result_id}</Link>
          </small>
        </TextContent>
      ),
    },
    {
      id: 'status',
      header: 'Status',
      sortingComparator: testcaseStatusSorter,
      cell: (item) => getStatusIconForStatus(item.testcaseStatus ?? 'UNTESTED'),
    },
    {
      id: 'testname',
      header: 'Test Name',
      sortingComparator: testNameSorter,
      cell: (item) => item.testResultMetadataMap['test_name']!,
    },
    {
      id: 'testcaseid',
      header: 'Test Case ID',
      sortingComparator: testCaseIdSorter,
      cell: (item) => item.testResultMetadataMap['test_case_id'],
    },
  ];
  const noScreenshotTestCaseTablePageSizePreference = {
    title: 'Select page size',
    options: [
      { value: 25, label: '25 resources' },
      { value: 50, label: '50 resources' },
    ],
  };
  const noScreenshotTestCaseTableVisibleContentPreference = {
    title: 'Select visible content',
    options: [
      {
        label: 'Test case configuration properties',
        options: [
          {
            id: 'id',
            label: 'Test Result ID',
            editable: false,
          },
          {
            id: 'status',
            label: 'Status',
            editable: false,
          },
          {
            id: 'testname',
            label: 'Test Name',
          },
          {
            id: 'testcaseid',
            label: 'Test Case ID',
          },
        ],
      },
    ],
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 25,
    visibleContent: ['id', 'status', 'testname'],
  });
  const { items, collectionProps, paginationProps } = useCollection(
    findIntersectionOf(props.filteredTestcases, props.testcases),
    {
      filtering: {},
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  return (
    <Table
      {...collectionProps}
      selectedItems={props.selectedItems}
      selectionType="multi"
      wrapLines={true}
      columnDefinitions={noScreenshotTestCaseTableColumnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      onSelectionChange={({ detail }) => {
        props.setSelectedItems(detail.selectedItems);
      }}
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          pageSizePreference={noScreenshotTestCaseTablePageSizePreference}
          visibleContentPreference={noScreenshotTestCaseTableVisibleContentPreference}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
    />
  );
};
export default NoScreenshotTestCaseTable;
