import { Button, Modal, Spinner } from '@amzn/awsui-components-react-v3';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Layout/Header';
import Layout from './components/Layout/Layout';
import AuthorizationContext from './hoc/AuthorizationContext';
import { NotificationProvider } from './hoc/NotificationContext';
import { TestCasesContextProvider } from './hoc/TestCasesContext';
import { authorizeuser, getLoginPage } from './remote/authorization';
import { isTokenValid } from './remote/tokenverifier';
import CookieService from './util/CookieService';

const queryClient = new QueryClient();

class App extends React.Component<{}, { idToken: string; isAuthorized: boolean }> {
  _isMounted = false;

  constructor(props: Readonly<{}>) {
    super(props);
    const token = CookieService.get('id_token');
    if (token) {
      this.state = { idToken: token, isAuthorized: true };
    } else {
      this.state = { idToken: '', isAuthorized: false };
    }
  }

  handleStatusCode = (code: number) => {
    if (code === 401) {
      this.setState({ idToken: '', isAuthorized: false });
    }
  };

  setIsAuthorized = (isAuthorized: boolean) => {
    this.setState({ ...this.state, isAuthorized: isAuthorized });
  };
  setIdToken = (token: string) => {
    this.setState({ ...this.state, idToken: token });
  };

  componentDidMount = async () => {
    this._isMounted = true;
    const cookieIdToken = CookieService.get('id_token');
    if (cookieIdToken && isTokenValid(cookieIdToken)) {
      this.setState({ idToken: cookieIdToken, isAuthorized: true });
      return;
    }

    const token = await authorizeuser();
    if (token) {
      this.setState({ idToken: token, isAuthorized: true });
    } else {
      this.setState({ idToken: '', isAuthorized: false });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };
  modal = (visible: boolean) => {
    return (
      <Modal
        visible={visible}
        header="You are logged out"
        footer={
          <span className="awsui-util-f-r">
            <Button href={getLoginPage()} iconAlign="right" iconName="external">
              Login
            </Button>
          </span>
        }
      >
        Click Login to proceed.
      </Modal>
    );
  };

  render() {
    return (
      <Suspense fallback={<Spinner size="big" variant="inverted" />}>
        <Header />
        <AuthorizationContext.Provider
          value={{
            idToken: this.state.idToken,
            isAuthorized: this.state.isAuthorized,
            setIsAuthorized: this.setIsAuthorized,
            setIdToken: this.setIdToken,
            handleStatusCode: this.handleStatusCode,
          }}
        >
          {this.state.isAuthorized ? (
            <QueryClientProvider client={queryClient}>
              <NotificationProvider>
                <TestCasesContextProvider>
                  <Layout />
                </TestCasesContextProvider>
              </NotificationProvider>
            </QueryClientProvider>
          ) : (
            this.modal(!this.state.isAuthorized)
          )}
        </AuthorizationContext.Provider>
      </Suspense>
    );
  }
}

export default App;
