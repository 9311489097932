import { getTestCaseIdsFromTestResults, ITestCaseResult } from '../SuiteDataAggregation';
import { Box, Button, Icon, Modal, SpaceBetween, TextContent } from '@amzn/awsui-components-react-v3';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { useMutation, useQueryClient } from 'react-query';
import { RequestError } from '../../../remote/request';
import { IRefetchTestCasesOptions, refetchTestCases } from '../../../remote/suites';
import { NotificationContext } from '../../../hoc/NotificationContext';

interface IRefetchTestCaseModal {
  containsTestcases: boolean;
  suiteId: string;
  configurationId: string;
  selectedItems: ITestCaseResult[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const RefetchTestCaseModal = (props: IRefetchTestCaseModal) => {
  const queryClient = useQueryClient();
  const { showErrorFlashbar, showInProgressFlashbar, showSuccessFlashbar } = useContext(NotificationContext);
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const refetch = useMutation<{ testResults: ITestCaseResult[] }, RequestError, IRefetchTestCasesOptions>(
    'refetchTestCases',
    (refetchTestCasesOptions) => refetchTestCases(refetchTestCasesOptions),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getTestcases');
        showSuccessFlashbar(`Refetched ${data.testResults.length} test cases.`);
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not refetch test cases: [${JSON.stringify(variables)}]. Error occurred: [${error.message}]`
        );
      },
      onMutate: () => showInProgressFlashbar(`Fetching test cases...`),
    }
  );
  const handleRefetch = () => {
    if (props.suiteId && props.configurationId && props.containsTestcases) {
      refetch.mutate({
        idToken: idToken,
        suiteId: props.suiteId,
        configurationId: props.configurationId,
        testResultIds: getTestCaseIdsFromTestResults(props.selectedItems),
      });
    } else {
      console.error('Can not refetch test cases. Unable to find suiteId or configurationId');
    }
  };

  return (
    <Modal
      onDismiss={() => props.setVisible(false)}
      visible={props.visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.setVisible(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                props.setVisible(false);
                handleRefetch();
              }}
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Refetch test cases"
    >
      <Box textAlign="center">
        <Icon name="status-warning" size="big" variant="warning" />
        <TextContent>
          ({props.selectedItems.length}) Selected test cases will be overridden. Are you sure you want to continue?
        </TextContent>
      </Box>
    </Modal>
  );
};
export default RefetchTestCaseModal;
