import { ITestRun } from './ITestOnDemand';
import { pageToTargetMap } from './LivingRoomTodConfig';
import { ITodParams } from './TestOnDemandCreate/TestOnDemandCreate';
import { PageType, TodRunConfigurationGenerator } from './TodRunConfigurationGenerator';
import { LivingRoomTodRunParameter } from './TodRunParameter';

export class LivingRoomTodRunConfigurationGenerator extends TodRunConfigurationGenerator {
  public createTestRuns(params: ITodParams): ITestRun[] {
    let testRuns: ITestRun[] = [];
    params.page.forEach((p: PageType) => {
      this.generateTargets(p, params.country, params.locale).forEach((t) => {
        testRuns.push({ runParameter: new LivingRoomTodRunParameter(t) });
      });
    });
    return testRuns;
  }
  private generateTargets(page: PageType, country: string[], locale: string[]): string[] {
    let targets: string[] = [];
    pageToTargetMap[page].forEach((p) => {
      targets.push(
        `test:${p} -- --environment prod --countries ${country
          .map((c) => {
            return c.toUpperCase();
          })
          .join(' ')} --l ${locale.join(' ')}`
      );
    });
    return targets;
  }
}
