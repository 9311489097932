import { useCollection } from '@amzn/awsui-collection-hooks';
import { Table, TextFilter } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { cardsConfig } from '../../../../Suites/SuitesBrowse/CardsConfig';

interface ResultDetailsMetadataProps {
  items: { key: string; value: any }[];
}

const ResultDetailsMetadata = (props: ResultDetailsMetadataProps) => {
  const { items, collectionProps, filterProps, filteredItemsCount } = useCollection(props.items, {
    filtering: {
      empty: cardsConfig.emptyCard,
      noMatch: cardsConfig.noMatch,
    },
    sorting: {},
  });
  const columnDefinitions: any = [
    { id: 'key', header: 'key', cell: (item: any) => item.key },
    { id: 'value', header: 'value', cell: (item: any) => item.value },
  ];

  return (
    <Table
      {...collectionProps}
      loadingText="Loading resources"
      columnDefinitions={columnDefinitions}
      items={items}
      wrapLines={true}
      resizableColumns={true}
      filter={
        <TextFilter
          {...filterProps}
          countText={filteredItemsCount + ' matches.'}
          filteringPlaceholder="Find metadata"
        />
      }
    />
  );
};
export default ResultDetailsMetadata;
