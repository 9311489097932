import validate from 'uuid-validate';
import { ITestOnDemand } from '../components/TestOnDemand/ITestOnDemand';
import { request } from './request';

export const getTestsOnDemand = (idToken: string): Promise<ITestOnDemand[]> => {
  return request(`/testsOnDemand`, {
    method: 'GET',
    headers: {
      Authorization: idToken,
    },
  });
};

export const createTestsOnDemand = (tod: ITestOnDemand, idToken: string): Promise<string[]> => {
  if (tod.testRuns.length === 0) {
    throw new Error('No test run targets.');
  }
  return request('/testsOnDemand', {
    method: 'POST',
    body: JSON.stringify([tod]),
    headers: {
      Authorization: idToken,
    },
  });
};

export const getTestOnDemandById = (todId: string, idToken: string): Promise<ITestOnDemand> => {
  if (!validate(todId)) {
    console.error(`Invalid test on demand id.`);
    throw new Error('Invalid test on demand id.');
  }
  return request(`/testsOnDemand/${todId}`, {
    headers: {
      Authorization: idToken,
    },
  });
};
