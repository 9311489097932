import { Container, PieChart } from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import EmptyBox from './EmptyBox';
import NoMatch from './NoMatch';
import { calculatePercentage } from './SuiteDataAggregation';

interface ITestCaseStatusChartProps {
  testCaseStatusCount?: {
    passed: number;
    failed: number;
    blocked: number;
    untested: number;
    total: number;
  };
  size?: 'small' | 'medium' | 'large';
}
export const calculateChartSegmentDescription = (datum: { value: number }, sum: number) =>
  `${datum.value} (${((datum.value / sum) * 100).toFixed(0)}%)`;

export const TestCaseStatusChart = (props: ITestCaseStatusChartProps) => {
  const color = {
    green: '#69ae34',
    red: '#d63f38',
    blue: '#08aad2',
    grey: '#879596',
  };

  const chartData = [
    {
      title: 'Passed',
      percentage: calculatePercentage(props.testCaseStatusCount!.passed, props.testCaseStatusCount!.total),
      value: props.testCaseStatusCount!.passed,
      color: color.green,
    },
    {
      title: 'Failed',
      percentage: calculatePercentage(props.testCaseStatusCount!.failed, props.testCaseStatusCount!.total),
      value: props.testCaseStatusCount!.failed,
      color: color.red,
    },
    {
      title: 'Blocked',
      percentage: calculatePercentage(props.testCaseStatusCount!.blocked, props.testCaseStatusCount!.total),
      value: props.testCaseStatusCount!.blocked,
      color: color.blue,
    },
    {
      title: 'Untested',
      percentage: calculatePercentage(props.testCaseStatusCount!.untested, props.testCaseStatusCount!.total),
      value: props.testCaseStatusCount!.untested,
      color: color.grey,
    },
  ];

  return (
    <Container data-testid="container-testcasestatuschart">
      <PieChart
        data={chartData}
        segmentDescription={calculateChartSegmentDescription}
        i18nStrings={{
          detailsValue: 'Value',
          detailsPercentage: 'Percentage',
          filterLabel: 'Filter test results',
          filterPlaceholder: '-- test case status --',
        }}
        errorText="Error loading data."
        innerMetricDescription="Test Cases"
        innerMetricValue={props.testCaseStatusCount!.total + ''}
        loadingText="Loading chart"
        recoveryText="Retry"
        size={props.size ? props.size : 'small'}
        variant="donut"
        empty={<EmptyBox boldText="No data" normalText="Fetch test results." />}
        noMatch={<NoMatch />}
      />
    </Container>
  );
};
export default TestCaseStatusChart;
