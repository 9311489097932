import { ITestRun } from './ITestOnDemand';
import { ITodParams } from './TestOnDemandCreate/TestOnDemandCreate';

export abstract class TodRunConfigurationGenerator {
  abstract createTestRuns(params: ITodParams): ITestRun[];
}
export type PageType =
  | 'billing'
  | 'confirmation'
  | 'details'
  | 'help'
  | 'mlp'
  | 'mystuff'
  | 'payment'
  | 'planselection'
  | 'playback'
  | 'search'
  | 'settings'
  | 'storefront';
export type DeviceType = 'android' | 'appletv' | 'livingroom' | 'firetv' | 'ios' | 'web';
export const hasSignupSupport = (deviceType: DeviceType): boolean => {
  return deviceType === 'livingroom';
};
export const hasSignupPages = (pages: PageType[]): boolean => {
  return (
    pages.includes('billing') ||
    pages.includes('confirmation') ||
    pages.includes('mlp') ||
    pages.includes('payment') ||
    pages.includes('planselection')
  );
};
