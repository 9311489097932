import { Box } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

interface IValueWithLabel {
  label: string;
  children: React.ReactNode;
}

export const ValueWithLabel = ({ label, children }: IValueWithLabel) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);
