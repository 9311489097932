import { Button, ColumnLayout, SegmentedControl, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';
import { ITestCaseResult } from '../SuiteDataAggregation';
import TestCaseTabs from './TestCaseTabs';

interface IResultDetailsComparisonProps {
  testCaseResult: ITestCaseResult;
  compareWithTestCaseResult: ITestCaseResult;
  visibleHandler: (enabled: boolean) => void;
}

type ComparisonMode = 'stacked' | 'sidebyside' | undefined;

const ResultDetailsComparison = (props: IResultDetailsComparisonProps) => {
  const [compareModeLayout, setCompareModeLayout] = useState<ComparisonMode>('sidebyside');

  return (
    <SpaceBetween size="s" direction="horizontal">
      <SegmentedControl
        selectedId={compareModeLayout as string}
        onChange={({ detail }) => setCompareModeLayout(detail.selectedId as ComparisonMode)}
        label="Default segmented control"
        options={[
          { text: 'side-by-side', id: 'sidebyside', iconName: 'view-vertical' },
          { text: 'stacked', id: 'stacked', iconName: 'view-horizontal' },
        ]}
      />
      <Button iconName="close" onClick={() => props.visibleHandler(false)} data-testid="button-compareclose">
        Close compare mode
      </Button>
      <ColumnLayout columns={compareModeLayout === 'stacked' ? 1 : 2}>
        <TestCaseTabs testCaseResult={props.testCaseResult} showLocale={true} />
        <TestCaseTabs testCaseResult={props.compareWithTestCaseResult} showLocale={true} />
      </ColumnLayout>
    </SpaceBetween>
  );
};
export default ResultDetailsComparison;
