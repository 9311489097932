import { MultiselectProps } from '@amzn/awsui-components-react-v3';
import { QueryType } from '../../Suites/QueryType';

export interface SingleSelectDropdownOptions {
  label: string;
  tags?: string[];
  value: string;
}
export const QueryTypeOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
  { label: 'Elastic Search', tags: ['Lucene Syntax'], value: QueryType.ES_LUCENE },
];
export const DeviceTypeOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
  { label: 'Android', tags: ['mobile'], value: 'android' },
  { label: 'AppleTVGen45', tags: ['livingroom'], value: 'appletv' },
  { label: 'Living Room', tags: ['blast', 'ignition', 'html5', 'ps4'], value: 'livingroom' },
  { label: 'FireTV', value: 'firetv' },
  { label: 'IOS', tags: ['mobile'], value: 'ios' },
  { label: 'Web ', tags: ['website'], value: 'web' },
];
export const CountryOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
  { label: 'AE', tags: ['United Arab Emirates'], value: 'ae' },
  { label: 'AO', tags: ['Angola'], value: 'ao' },
  { label: 'AR ', tags: ['Argentina'], value: 'ar' },
  { label: 'AU', tags: ['Australia'], value: 'au' },
  { label: 'BD', tags: ['Bangladesh'], value: 'bd' },
  { label: 'BE', tags: ['Belgium'], value: 'be' },
  { label: 'BO', tags: ['Bolivia'], value: 'bo' },
  { label: 'BR', tags: ['Brazil'], value: 'br' },
  { label: 'CA', tags: ['Canada'], value: 'ca' },
  { label: 'CH', tags: ['Switzerland'], value: 'ch' },
  { label: 'CL', tags: ['Chile'], value: 'cl' },
  { label: 'CO', tags: ['Colombia'], value: 'co' },
  { label: 'CZ', tags: ['Czech Republic'], value: 'cz' },
  { label: 'DE', tags: ['Germany'], value: 'de' },
  { label: 'DK', tags: ['Denmark'], value: 'dk' },
  { label: 'ES', tags: ['Spain'], value: 'es' },
  { label: 'GB', tags: ['United Kingdom', 'GB'], value: 'gb' },
  { label: 'SA', tags: ['Saudi Arabia'], value: 'sa' },
  { label: 'FR', tags: ['France'], value: 'fr' },
  { label: 'HU', tags: ['Hungary'], value: 'hu' },
  { label: 'ID', tags: ['Indonesia'], value: 'id' },
  { label: 'IE', tags: ['Ireland'], value: 'ie' },
  { label: 'IN', tags: ['India'], value: 'in' },
  { label: 'IL', tags: ['Israel'], value: 'il' },
  { label: 'IT', tags: ['Italy'], value: 'it' },
  { label: 'JP', tags: ['Japan'], value: 'jp' },
  { label: 'LU', tags: ['Luxembourg'], value: 'lu' },
  { label: 'MX', tags: ['Mexico'], value: 'mx' },
  { label: 'NL', tags: ['Netherlands'], value: 'nl' },
  { label: 'NO', tags: ['Norway'], value: 'no' },
  { label: 'NZ', tags: ['New Zealand'], value: 'nz' },
  { label: 'PH', tags: ['Philippines'], value: 'ph' },
  { label: 'PL', tags: ['Poland'], value: 'pl' },
  { label: 'PT', tags: ['Portugal'], value: 'pt' },
  { label: 'RO', tags: ['Romania'], value: 'ro' },
  { label: 'SE', tags: ['Sweden'], value: 'se' },
  { label: 'SG', tags: ['Singapore'], value: 'sg' },
  { label: 'UK', tags: ['United Kingdom', 'UK'], value: 'uk' },
  { label: 'US', tags: ['United States', 'USA'], value: 'us' },
  { label: 'VN', tags: ['Vietnam'], value: 'vn' },
  { label: 'ZA', tags: ['South Africa'], value: 'za' },
];
export const PageTypeOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
  { label: 'Billing Address', tags: ['signup'], value: 'billing' },
  { label: 'Confirmation', tags: ['signup'], value: 'confirmation' },
  { label: 'Details', value: 'details' },
  { label: 'Help', value: 'help' },
  { label: 'MLP', tags: ['marketinglanding', 'signup'], value: 'mlp' },
  { label: 'MyStuff', value: 'mystuff' },
  { label: 'Payment', tags: ['signup'], value: 'payment' },
  { label: 'Plan Selection', tags: ['signup'], value: 'planselection' },
  { label: 'Playback', value: 'playback' },
  { label: 'Search', value: 'search' },
  { label: 'Settings', value: 'settings' },
  { label: 'Storefront', value: 'storefront' },
];

export const LocaleOptions: SingleSelectDropdownOptions[] | MultiselectProps.Options = [
  { label: 'العربية', tags: ['ar_AE', 'Arabic (United Arab Emirates)'], value: 'ar_AE' },
  { label: 'Bahasa Indonesia', tags: ['id_ID', 'Indonesian'], value: 'id_ID' },
  { label: 'Czech', tags: ['cs_CZ', 'Czech'], value: 'cs_CZ' },
  { label: 'Dansk ', tags: ['da_DK', 'Danish'], value: 'da_DK' },
  { label: 'Deutsch', tags: ['de_DE', 'German'], value: 'de_DE' },
  { label: 'English', tags: ['en_US', 'English US'], value: 'en_US' },
  { label: 'English', tags: ['en_GB', 'English GB'], value: 'en_GB' },
  { label: 'Español', tags: ['es_ES', 'Spanish ES'], value: 'es_ES' },
  { label: 'Español', tags: ['es_US', 'Spanish US'], value: 'es_US' },
  { label: 'Filipino', tags: ['fil_PH', 'Filipino', 'Philippines'], value: 'fil_PH' },
  { label: 'Français', tags: ['fr_FR', 'French'], value: 'fr_FR' },
  { label: 'Greek (Greece)', tags: ['el_GR', 'Greek'], value: 'el_GR' },
  { label: 'עברית', tags: ['he_IL', 'Hebrew (Israel)'], value: 'he_IL' },
  { label: 'Hungarian', tags: ['hu_HU', 'Hungarian'], value: 'hu_HU' },
  { label: 'Italiano', tags: ['it_IT', 'Italian'], value: 'it_IT' },
  { label: 'Japanese', tags: ['ja_JP', 'Japanese'], value: 'ja_JP' },
  { label: 'Malay', tags: ['ms_MY', 'Malay', 'Malaysia'], value: 'ms_MY' },
  { label: 'Nederlands', tags: ['nl_NL', 'Dutch'], value: 'nl_NL' },
  { label: 'Norsk', tags: ['nb_NO', 'Norwegian'], value: 'nb_NO' },
  { label: 'Polski', tags: ['pl_PL', 'Polish'], value: 'pl_PL' },
  { label: 'Português (Brasil)', tags: ['pt_BR', 'Brazilian Portuguese'], value: 'pt_BR' },
  { label: 'Português (Portugal)', tags: ['pt_PT', 'European Portuguese'], value: 'pt_PT' },
  { label: 'Romanian (Romania)', tags: ['ro_RO', 'Romanian'], value: 'ro_RO' },
  { label: 'Suomi', tags: ['fi_FI', 'Finnish'], value: 'fi_FI' },
  { label: 'Svenska', tags: ['sv_SE', 'Swedish'], value: 'sv_SE' },
  { label: 'Türkçe', tags: ['tr_TR', 'Turkish'], value: 'tr_TR' },
  { label: 'Русский', tags: ['ru_RU', 'Russian'], value: 'ru_RU' },
  { label: 'हिन्दी', tags: ['hi_IN', 'Hindi'], value: 'hi_IN' },
  { label: 'தமிழ்', tags: ['ta_IN', 'Tamil'], value: 'ta_IN' },
  { label: 'తెలుగు', tags: ['te_IN', 'Telugu'], value: 'te_IN' },
  { label: 'ไทย', tags: ['th_TH', 'Thai'], value: 'th_TH' },
  { label: '简体中文', tags: ['zh_CN', 'Chinese Simplified'], value: 'zh_CN' },
  { label: '繁體中文', tags: ['zh_TW', 'Chinese Traditional'], value: 'zh_TW' },
  { label: '한국어', tags: ['ko_KR', 'Korean'], value: 'ko_KR' },
  { label: 'Pseudo RTL', tags: ['ar_XD', 'Pseudo Right To Left'], value: 'ar_XD' },
  { label: 'Pseudo LTR', tags: ['en_XD', 'Pseudo Left To Right'], value: 'en_XD' },
];
