import {
  Box,
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  Container,
  Header,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { TestCasesContext } from '../../../hoc/TestCasesContext';
import { RequestError } from '../../../remote/request';
import { getTestCaseById, setTestCaseStatus } from '../../../remote/suites';
import { getBreadcrumbItemsFromLocation } from '../../../util/stringutil';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import { getStatusIconForStatus } from '../Runs/Run';
import { ITestCaseResult, ITestCaseStatus } from '../SuiteDataAggregation';
import ResultDetailsComparison from './ResultDetailsComparison';
import TestCaseTabs from './TestCaseTabs';

interface ITestCaseProps extends RouteComponentProps {
  configurationId?: string;
  suiteId?: string;
  testResultId?: string;
}

const TestCase = (props: ITestCaseProps) => {
  const [compareModeEnabled, setCompareModeEnabled] = useState(false);
  const [compareWithLocale, setCompareWithLocale] = useState('');
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const [testCaseQueryEnabled, setTestCaseQueryEnabled] = useState(true);
  const { showErrorFlashbar, showSuccessFlashbar, showInProgressFlashbar } = useContext(NotificationContext);
  const { hasNext, hasPrevious, nextTestCase, previousTestCase, testCases } = useContext(TestCasesContext);
  const location = useLocation();
  const getTestCaseQuery = useQuery(
    'getTestCaseById',
    () => getTestCaseById(idToken, props.suiteId!, props.configurationId!, props.testResultId!),
    {
      retry: false,
      enabled: testCaseQueryEnabled,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Failed to fetch test case with id [${props.testResultId}]. The link you're trying to access has expired. Internal users can retrieve the result directly from Mobile QA dashboard: (https://prod.testresultdashboard.video.amazon.dev/#/result?result=${props.testResultId}).`
        );
        setTestCaseQueryEnabled(false);
      },
      onSuccess: () => {
        setTestCaseQueryEnabled(false);
      },
    }
  );

  useEffect(() => {
    setTestCaseQueryEnabled(true);
    setCompareModeEnabled(false);
  }, [props.testResultId]);

  const getTestCaseWithLocaleQuery = useQuery(
    'getTestCaseByIdWithLocale',
    () => getTestCaseById(idToken, props.suiteId!, props.configurationId!, props.testResultId!, compareWithLocale),
    {
      enabled: compareModeEnabled,
      retry: false,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Failed to retrieve image to compare. Make sure it was generated in the ${compareWithLocale} locale.`
        );
        setCompareWithLocale('');
        setCompareModeEnabled(false);
      },
    }
  );
  const setTestCaseStatusQuery = useMutation<
    ITestCaseResult,
    RequestError,
    { idToken: string; suiteId: string; configurationId: string; testResultId: string; testcaseStatus: ITestCaseStatus }
  >(
    'setTestCaseStatus',
    (args) =>
      setTestCaseStatus(args.idToken, args.suiteId, args.configurationId, args.testResultId, args.testcaseStatus),
    {
      onSuccess: (data) => {
        setTestCaseQueryEnabled(true);
        showSuccessFlashbar(`Updated status for id ${data.testResultId}.`);
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not set test case status for: [${JSON.stringify(variables)}]. Error occurred: [${error.message}].`
        );
      },
      onMutate: () => showInProgressFlashbar(`Updating status...`),
    }
  );
  const handleSetTestCaseStatus = (testcaseStatus: ITestCaseStatus) => {
    setTestCaseStatusQuery.mutate({
      idToken: idToken,
      suiteId: props.suiteId!,
      configurationId: props.configurationId!,
      testResultId: props.testResultId!,
      testcaseStatus: testcaseStatus,
    });
  };

  const handleCompareWith = (locale: string) => {
    setCompareWithLocale(locale);
    setCompareModeEnabled(true);
  };

  const showError = () => {
    return <GoToHomeButton />;
  };

  const showSpinner = () => {
    return <Spinner size="large" />;
  };

  const showContent = () => {
    return (
      <SpaceBetween direction="vertical" size="l">
        <BreadcrumbGroup items={getBreadcrumbItemsFromLocation(location.pathname)} data-testid="breadcrumbgroup" />
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <ButtonDropdown
                    disabled={
                      getTestCaseQuery.data!.screenshots?.length === 0 ||
                      !getTestCaseQuery.data!.testResultMetadataMap.additional_data$locale
                    }
                    items={[
                      {
                        text: 'en_US',
                        id: 'en_US',
                        disabled: 'en_US' === getTestCaseQuery.data!.testResultMetadataMap.additional_data$locale,
                      },
                    ]}
                    onItemClick={(event) => {
                      handleCompareWith(event.detail.id);
                    }}
                    data-testid="button-comparewith"
                  >
                    Compare with
                  </ButtonDropdown>
                  <ButtonDropdown
                    disabled={getTestCaseQuery.data!.screenshots?.length === 0}
                    items={[
                      { text: 'Passed', id: 'passed', disabled: getTestCaseQuery.data!.testcaseStatus === 'PASSED' },
                      { text: 'Failed', id: 'failed', disabled: getTestCaseQuery.data!.testcaseStatus === 'FAILED' },
                      { text: 'Blocked', id: 'blocked', disabled: getTestCaseQuery.data!.testcaseStatus === 'BLOCKED' },
                      {
                        text: 'Untested',
                        id: 'untested',
                        disabled:
                          getTestCaseQuery.data!.testcaseStatus === 'UNTESTED' ||
                          !getTestCaseQuery.data!.testcaseStatus,
                      },
                    ]}
                    onItemClick={(event) => {
                      handleSetTestCaseStatus(event.detail.id.toUpperCase() as ITestCaseStatus);
                    }}
                  >
                    Change status
                  </ButtonDropdown>
                </SpaceBetween>
              }
            >
              {getTestCaseQuery.data!.testResultId}
            </Header>
          }
          data-testid="container-actions"
        >
          {getStatusIconForStatus(getTestCaseQuery.data!.testcaseStatus!)}
        </Container>
        {testCases.length > 0
          ? getTestCaseQuery.isSuccess &&
            getTestCaseQuery.data && (
              <Box textAlign="center">
                <Button
                  iconName="angle-left"
                  disabled={!hasPrevious(props.testResultId!)}
                  onClick={async () => {
                    await navigate(previousTestCase(props.testResultId!).testResultId);
                  }}
                >
                  Previous
                </Button>
                <Button
                  iconName="angle-right"
                  disabled={!hasNext(props.testResultId!)}
                  onClick={async () => {
                    await navigate(nextTestCase(props.testResultId!).testResultId);
                  }}
                >
                  Next
                </Button>
              </Box>
            )
          : null}
        {compareModeEnabled ? (
          <Container>
            {getTestCaseWithLocaleQuery.isError && showError()}
            {getTestCaseWithLocaleQuery.isLoading && showSpinner()}
            {getTestCaseWithLocaleQuery.isSuccess && (
              <ResultDetailsComparison
                testCaseResult={getTestCaseQuery.data!}
                compareWithTestCaseResult={getTestCaseWithLocaleQuery.data!}
                visibleHandler={setCompareModeEnabled}
              />
            )}
          </Container>
        ) : (
          <TestCaseTabs
            testCaseResult={getTestCaseQuery.data!}
            showLocale={false}
            data-testid="container-testcasetabs"
          />
        )}
      </SpaceBetween>
    );
  };

  return (
    <Box padding="l" textAlign="center">
      {getTestCaseQuery.isError && showError()}
      {getTestCaseQuery.isLoading && showSpinner()}
      {getTestCaseQuery.isSuccess && showContent()}
    </Box>
  );
};
export default TestCase;
