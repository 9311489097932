import React from 'react';

interface TestcaseDetailsImageProps {
  screenshotUrl: string;
}

const TestcaseDetailsImage = (props: TestcaseDetailsImageProps) => {
  return <img src={props.screenshotUrl} style={{ maxWidth: '100%' }} alt={'testcase'} />;
};

export default TestcaseDetailsImage;
