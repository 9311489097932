import { Box, Link, ProgressBar, StatusIndicator, Table, TableProps } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { calculatePercentage } from '../Suites/SuiteDataAggregation';
import { ITestOnDemand, ITestRun } from './ITestOnDemand';

export const splitPaneli18Strings = {
  preferencesTitle: 'Split panel preferences',
  preferencesPositionLabel: 'Split panel position',
  preferencesPositionDescription: 'Choose the default split panel position for the service.',
  preferencesPositionSide: 'Side',
  preferencesPositionBottom: 'Bottom',
  preferencesConfirm: 'Confirm',
  preferencesCancel: 'Cancel',
  closeButtonAriaLabel: 'Close panel',
  openButtonAriaLabel: 'Open panel',
  resizeHandleAriaLabel: 'Resize split panel',
};
export const testResourceTableColumnDefinitions: TableProps.ColumnDefinition<ITestOnDemand>[] = [
  {
    id: 'id',
    header: 'Test Resource ID',
    cell: (item) => item.id,
  },
  {
    id: 'status',
    header: 'Status',
    sortingField: 'status',
    cell: (item) => getStatusIndicator(item),
  },
  {
    id: 'createdat',
    header: 'Created at',
    cell: (item) => new Date(item.createdAt!).toLocaleString(),
  },
  {
    id: 'duration',
    header: 'Duration',
    cell: (item) => calculateDuration(item.updatedAt!, item.createdAt!),
  },
  {
    id: 'devicetype',
    header: 'Device Types',
    cell: (item) => getDeviceTypeFromQuery(item.testSuiteConfiguration.query).join(', '),
  },
  {
    id: 'country',
    header: 'Countries',
    cell: (item) => getCountryFromQuery(item.testSuiteConfiguration.query).join(', '),
  },
  { id: 'page', header: 'Page', cell: (item) => getPageFromQuery(item.testSuiteConfiguration.query).join(', ') },
  {
    id: 'locale',
    header: 'Locales',
    cell: (item) => getLocaleFromQuery(item.testSuiteConfiguration.query).join(', '),
  },
];
export const testRunTableColumnDefinitions: TableProps.ColumnDefinition<ITestRun>[] = [
  {
    id: 'id',
    header: 'ToD ID',
    cell: (item) => item.id,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => item.resultStatus,
  },
  {
    id: 'url',
    header: 'Url (Amazon Internal only)',
    cell: (item) => (
      <Link external={true} href={item.resultUrl}>
        {item.resultUrl}
      </Link>
    ),
  },
];
export const testResourceTablePageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 25, label: '25 resources' },
    { value: 50, label: '50 resources' },
  ],
};
export const testResourceTableVisibleContentPreference = {
  title: 'Select visible content',
  options: [
    {
      label: 'Test Configuration properties',
      options: [
        {
          id: 'id',
          label: 'Test Resource ID',
        },
        {
          id: 'status',
          label: 'Status',
        },
        {
          id: 'duration',
          label: 'Duration',
        },
        {
          id: 'createdat',
          label: 'Created at',
        },
        {
          id: 'devicetype',
          label: 'Device Type',
        },
        {
          id: 'country',
          label: 'Country',
        },
        {
          id: 'page',
          label: 'Page',
        },
        {
          id: 'locale',
          label: 'Locale',
        },
      ],
    },
  ],
};
const getValueFromLuceneQuery = (query: string, key: string) => {
  const regex = new RegExp(key + ':"(\\w+)"', 'g');
  const results = [...query.matchAll(regex)];
  return results.map((r) => {
    return r[1];
  });
};
const getPageFromQuery = (queryString: string) => {
  return getValueFromLuceneQuery(queryString, 'page');
};
const getDeviceTypeFromQuery = (queryString: string) => {
  return getValueFromLuceneQuery(queryString, 'device_type');
};
const getCountryFromQuery = (queryString: string) => {
  return getValueFromLuceneQuery(queryString, 'country');
};
const getLocaleFromQuery = (queryString: string) => {
  return getValueFromLuceneQuery(queryString, 'locale');
};
export const addSubmitTimeToQuery = (start: string, end: string, queryString: string) => {
  return `submit_time:[${start} TO ${end}] AND ${queryString}`;
};
const getStatusIndicator = (tod: ITestOnDemand): JSX.Element => {
  let indicator: JSX.Element = <StatusIndicator type="success">Success</StatusIndicator>;
  const results = tod.testRuns.map((testrun) => {
    return testrun.resultStatus;
  });
  if (results.includes('IN_PROGRESS')) {
    const total = tod.testRuns.length;
    indicator = (
      <ProgressBar
        value={calculatePercentage(total - getNumberOfInprogressRuns(tod.testRuns), total)}
        description="In-progress"
      />
    );
  } else if (results.includes('FAILED')) {
    indicator = <StatusIndicator type="error">Failed</StatusIndicator>;
  } else if (results.length === 0) {
    indicator = <StatusIndicator type="pending">Pending</StatusIndicator>;
  }
  return indicator;
};

const calculateDuration = (startDate: string, endDate: string): string => {
  let milis = new Date(startDate).valueOf() - new Date(endDate).valueOf();
  const mm = Math.floor(milis / 1000 / 60);
  milis -= mm * 1000 * 60;
  const ss = Math.floor(milis / 1000);
  milis -= ss * 1000;
  return `${mm}m${ss}s`;
};

const getNumberOfInprogressRuns = (testRuns: ITestRun[]) => {
  return testRuns.filter((t) => {
    return t.resultStatus === 'IN_PROGRESS';
  }).length;
};

export const getSplitPanelContent = (items: ITestOnDemand[]) => {
  if (items.length === 1) {
    return {
      header: items[0].id!,
      children: (
        <Box padding="l">
          <Table columnDefinitions={testRunTableColumnDefinitions} items={items[0].testRuns} />
        </Box>
      ),
    };
  } else {
    return {
      header: `${items.length} Test Resources selected`,
      children: undefined,
    };
  }
};
export const getWebsocketApiUrl = () => {
  return window.location.hostname === 'verifeye.tools.a2z.com'
    ? 'wss://41g9enwd62.execute-api.us-east-1.amazonaws.com/prod'
    : 'wss://353phwvatk.execute-api.us-east-1.amazonaws.com/beta';
};
export const getResourceIdsToSubscribe = (tod: ITestOnDemand[], subscribedIds: string[]): string[] => {
  let toSubscribe: string[] = [];
  tod.forEach((i) => {
    if (i.status === 'IN_PROGRESS' && !subscribedIds.includes(i.id!)) {
      toSubscribe.push(i.id!);
    }
  });
  return toSubscribe;
};
