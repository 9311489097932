import * as React from 'react';
import { ISuite } from '../ISuite';
import { getTestCaseStatusFromSuite, getTotalNumberOfTestCasesFromSuite } from '../SuiteDataAggregation';
import TestCaseStatusChart from '../TestCaseStatusChart';

interface ISuiteSummaryProps {
  suite: ISuite;
}

export const SuiteSummary = (props: ISuiteSummaryProps) => {
  const [passed, failed, blocked, untested] = getTestCaseStatusFromSuite(props.suite);
  const total = getTotalNumberOfTestCasesFromSuite(props.suite);
  return (
    <TestCaseStatusChart
      testCaseStatusCount={{ passed: passed, failed: failed, blocked: blocked, untested: untested, total: total }}
      size="medium"
    />
  );
};
export default SuiteSummary;
