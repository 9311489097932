import { Alert, Box, BreadcrumbGroup, SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import { RequestError } from '../../../remote/request';
import { getSuiteById } from '../../../remote/suites';
import { getBreadcrumbItemsFromLocation } from '../../../util/stringutil';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import { ISuite } from '../ISuite';
import { getNumberOfNotStartedConfigurations } from '../SuiteDataAggregation';
import SuiteDetails from './SuiteDetails';
import SuiteHeader from './SuiteHeader';
import SuiteSummary from './SuiteSummary';

export interface SuiteProps extends RouteComponentProps {
  suiteId?: string;
}

const UnstartedWarning = ({ suite }: { suite: ISuite }) => {
  const numberOfNotStartedConfigurations = getNumberOfNotStartedConfigurations(suite);
  return (
    <Alert
      visible={numberOfNotStartedConfigurations > 0}
      dismissAriaLabel="Close alert"
      type="error"
      data-testid="alert-unstartedwarning"
    >
      <TextContent>
        You have <b>{numberOfNotStartedConfigurations}</b> not started configuration(s). Click <b>'Run'</b> to fetch
        test cases.
        <br />
        <i>Chart only shows started configurations data.</i>
      </TextContent>
    </Alert>
  );
};

const Suite = (props: SuiteProps) => {
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const { showErrorFlashbar } = useContext(NotificationContext);
  const location = useLocation();
  const { data, isLoading, isError, isSuccess } = useQuery(
    'getSuiteById',
    () => getSuiteById(props.suiteId!, idToken, true, true),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: RequestError) => {
        handleStatusCode(error.status);
        showErrorFlashbar(`Failed to fetch suite with id [${props.suiteId}]. Error: [${error.message}]`);
      },
    }
  );

  const showError = () => {
    return <GoToHomeButton />;
  };

  const showSpinner = () => {
    return <Spinner size="large" />;
  };

  const showContent = () => {
    return (
      <SpaceBetween direction="vertical" size="l">
        <BreadcrumbGroup items={getBreadcrumbItemsFromLocation(location.pathname)} data-testid="breadcrumbgroup" />
        <SuiteHeader suite={data!} />
        <UnstartedWarning suite={data!} />
        <SuiteSummary suite={data!} />
        <SuiteDetails configurations={data!.configurations} suiteId={props.suiteId!} />
      </SpaceBetween>
    );
  };

  return (
    <Box padding="l" textAlign="center">
      {isError && showError()}
      {isLoading && showSpinner()}
      {isSuccess && showContent()}
    </Box>
  );
};
export default Suite;
