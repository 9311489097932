import {
  Box,
  BreadcrumbGroup,
  Button,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AuthorizationContext from '../../../hoc/AuthorizationContext';
import { NotificationContext } from '../../../hoc/NotificationContext';
import SuitesEditHelpPanelContent from '../../../hoc/SuitesEditHelpPanelContent';
import { RequestError } from '../../../remote/request';
import {
  addConfigurationsToSuite,
  deleteConfigurationsFromSuite,
  getSuiteById,
  updateGeneralSettingsInSuite,
} from '../../../remote/suites';
import { buildDefaultConfigs, convertConfigurationsToQueries, getQueries } from '../../../util/ConfigurationUtil';
import { getBreadcrumbItemsFromLocation, getUTCDateString } from '../../../util/stringutil';
import HelpPanelInfoIcon from '../../Help/HelpPanelInfoIcon';
import GoToHomeButton from '../../Help/Home/GoToHomeButton';
import { IConfiguration } from '../IConfiguration';
import { IQuery } from '../IQuery';
import { ISuite } from '../ISuite';
import ConfigurationsContainer from './ConfigurationsContainer';
import GeneralSettingsContainer from './GeneralSettingsContainer';
import QueryContainer from './QueryContainer';

interface ISuitesEditProps {
  suiteId?: string;
}

const SuitesEdit = (props: ISuitesEditProps & RouteComponentProps) => {
  const [neededBy, setNeededBy] = useState('');
  const [note, setNote] = useState('');
  const [title, setTitle] = useState('');
  const { showSuccessFlashbar, showInProgressFlashbar, showErrorFlashbar } = useContext(NotificationContext);
  const [configurations, setConfigurations] = useState<IConfiguration[]>([]);
  const [queries, setQueries] = useState<IQuery[]>([]);
  const { idToken, handleStatusCode } = useContext(AuthorizationContext);
  const queryClient = useQueryClient();
  const location = useLocation();
  const getSuiteByIdQuery = useQuery('getSuiteById', () => getSuiteById(props.suiteId!, idToken, true, true), {
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error: RequestError) => {
      handleStatusCode(error.status);
      showErrorFlashbar(`Failed to fetch suite with id [${props.suiteId}]. Error: [${error.message}]`);
    },
    onSuccess: (data: ISuite) => {
      setNeededBy(data.neededBy);
      setTitle(data.title);
      if (data.note) setNote(data.note);
      setConfigurations(buildDefaultConfigs(data.configurations, false));
      setQueries(getQueries(data.configurations, false));
    },
  });

  const updateGeneralSettingsInSuiteQuery = useMutation<
    null,
    RequestError,
    { suiteId: string; idToken: string; newSuite: { neededBy?: string; note?: string; title?: string } }
  >(
    'updateGeneralSettingsInSuiteQuery',
    (args) => updateGeneralSettingsInSuite(args.suiteId, args.idToken, args.newSuite),
    {
      onSuccess: () => {
        !addConfigurationsToSuiteQuery.isLoading &&
          !deleteConfigurationsFromSuiteQuery.isLoading &&
          navigate(`/suites/browse/${getSuiteByIdQuery.data!.id}`);
        showSuccessFlashbar('Done.');
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not update settings for: [${JSON.stringify(variables)}]. Error occurred: [${error.message}].`
        );
      },
      onMutate: () => {
        showInProgressFlashbar(`Updating...`);
      },
    }
  );
  const addConfigurationsToSuiteQuery = useMutation<
    { configurationIds: string[] },
    RequestError,
    { suiteId: string; idToken: string; configurations: IConfiguration[] }
  >(
    'addConfigurationsToSuiteQuery',
    (args) => addConfigurationsToSuite(args.suiteId, args.idToken, args.configurations),
    {
      onSuccess: () => {
        !updateGeneralSettingsInSuiteQuery.isLoading &&
          !deleteConfigurationsFromSuiteQuery.isLoading &&
          navigate(`/suites/browse/${getSuiteByIdQuery.data!.id}`);
        queryClient.invalidateQueries('getSuiteById');
        showSuccessFlashbar('Done.');
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not add configurations for: [${JSON.stringify(variables)}]. Error occurred: [${error.message}].`
        );
      },
      onMutate: () => {
        showInProgressFlashbar(`Updating...`);
      },
    }
  );
  const deleteConfigurationsFromSuiteQuery = useMutation<
    null,
    RequestError,
    { suiteId: string; idToken: string; configurationIds: string[] }
  >(
    'deleteConfigurationsFromSuiteQuery',
    (args) => deleteConfigurationsFromSuite(args.suiteId, args.idToken, args.configurationIds),
    {
      onSuccess: () => {
        !updateGeneralSettingsInSuiteQuery.isLoading &&
          !addConfigurationsToSuiteQuery.isLoading &&
          navigate(`/suites/browse/${getSuiteByIdQuery.data!.id}`);
        queryClient.invalidateQueries('getSuiteById');
        showSuccessFlashbar('Done.');
      },
      onError: (error, variables) => {
        handleStatusCode(error.status);
        showErrorFlashbar(
          `Could not delete configurations for: [${JSON.stringify(variables)}]. Error occurred: [${error.message}].`
        );
      },
      onMutate: () => {
        showInProgressFlashbar(`Updating...`);
      },
    }
  );
  const handleEditSuite = async () => {
    if (hasNoteChanged() || hasDateChanged || hasTitleChanged()) {
      let suite = {};
      if (hasNoteChanged()) {
        suite = { ...suite, note: note };
      }
      if (hasDateChanged()) {
        suite = { ...suite, neededBy: getUTCDateString(neededBy) };
      }
      if (hasTitleChanged()) {
        suite = { ...suite, title: title };
      }
      await updateGeneralSettingsInSuiteQuery.mutateAsync({
        suiteId: getSuiteByIdQuery.data!.id!,
        idToken: idToken,
        newSuite: suite,
      });
    }
    if (hasConfigurationsChanged()) {
      if (getConfigurationsToAdd().length !== 0) {
        await addConfigurationsToSuiteQuery.mutateAsync({
          suiteId: getSuiteByIdQuery.data!.id!,
          idToken: idToken,
          configurations: getConfigurationsToAdd(),
        });
      }
      if (getConfigurationIdsToDelete().length !== 0) {
        await deleteConfigurationsFromSuiteQuery.mutateAsync({
          suiteId: getSuiteByIdQuery.data!.id!,
          idToken: idToken,
          configurationIds: getConfigurationIdsToDelete(),
        });
      }
    }
  };
  const getConfigurationsToAdd = () => {
    const defaultConfigsToAdd = configurations.filter((c) => {
      return !JSON.stringify(
        buildDefaultConfigs(getSuiteByIdQuery.data!.configurations, false).map((c) => {
          return { device: c.device, country: c.country, page: c.page, locale: c.locale };
        })
      ).includes(JSON.stringify({ device: c.device, country: c.country, page: c.page, locale: c.locale }));
    });
    const queriesToAdd = queries.filter((q) => {
      return !JSON.stringify(
        getQueries(getSuiteByIdQuery.data!.configurations, false).map((q) => {
          return { query: q.query, queryType: q.queryType, queryTitle: q.queryTitle };
        })
      ).includes(JSON.stringify({ query: q.query, queryType: q.queryType, queryTitle: q.queryTitle }));
    });
    return convertConfigurationsToQueries(defaultConfigsToAdd).concat(queriesToAdd);
  };
  const getConfigurationIdsToDelete = (): string[] => {
    const defaultConfigIdsToDelete: string[] = buildDefaultConfigs(getSuiteByIdQuery.data!.configurations, false)
      .filter((c) => {
        return !JSON.stringify(
          configurations.map((c) => {
            return { device: c.device, country: c.country, page: c.page, locale: c.locale };
          })
        ).includes(JSON.stringify({ device: c.device, country: c.country, page: c.page, locale: c.locale }));
      })
      .map((c) => c.id!);
    const queryIdsToDelete: string[] = getQueries(getSuiteByIdQuery.data!.configurations, false)
      .filter((q) => {
        return !JSON.stringify(
          queries.map((q) => {
            return { query: q.query, queryType: q.queryType, queryTitle: q.queryTitle };
          })
        ).includes(JSON.stringify({ query: q.query, queryType: q.queryType, queryTitle: q.queryTitle }));
      })
      .map((q) => q.id!);
    return defaultConfigIdsToDelete.concat(queryIdsToDelete);
  };
  const hasTitleChanged = () => {
    return title !== getSuiteByIdQuery.data!.title;
  };
  const hasDateChanged = () => {
    return getUTCDateString(neededBy) !== getUTCDateString(getSuiteByIdQuery.data!.neededBy);
  };
  const hasNoteChanged = () => {
    return getSuiteByIdQuery.data!.note ? note !== getSuiteByIdQuery.data!.note : note !== '';
  };
  const hasConfigurationsChanged = () => {
    return (
      JSON.stringify(
        configurations.map((c) => {
          return { device: c.device, country: c.country, page: c.page, locale: c.locale };
        })
      ) !==
        JSON.stringify(
          buildDefaultConfigs(getSuiteByIdQuery.data!.configurations, false).map((c) => {
            return { device: c.device, country: c.country, page: c.page, locale: c.locale };
          })
        ) ||
      JSON.stringify(
        queries.map((q) => {
          return { query: q.query, queryType: q.queryType, queryTitle: q.queryTitle };
        })
      ) !==
        JSON.stringify(
          getQueries(getSuiteByIdQuery.data!.configurations, false).map((q) => {
            return { query: q.query, queryType: q.queryType, queryTitle: q.queryTitle };
          })
        )
    );
  };
  const allConfigurationsRemoved = () => {
    return (
      buildDefaultConfigs(getSuiteByIdQuery.data!.configurations, false).length +
        getQueries(getSuiteByIdQuery.data!.configurations, false).length -
        getConfigurationIdsToDelete().length +
        getConfigurationsToAdd().length ===
      0
    );
  };
  const showError = () => {
    return <GoToHomeButton />;
  };

  const showSpinner = () => {
    return <Spinner size="large" />;
  };

  const showContent = () => {
    return (
      <Box padding="xxl">
        <BreadcrumbGroup
          data-testid="suites-edit-breadcrumbs"
          items={getBreadcrumbItemsFromLocation(location.pathname)}
        />
        <Form
          data-testid="suites-edit-editform"
          header={
            <Header variant="h1" description="Fill in the form. All fields except 'Notes' are mandatory.">
              Edit Test Suite {<HelpPanelInfoIcon content={<SuitesEditHelpPanelContent />} />}
            </Header>
          }
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                onClick={() => {
                  navigate(-1);
                }}
                disabled={getSuiteByIdQuery.isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleEditSuite();
                }}
                disabled={
                  (!hasTitleChanged() && !hasDateChanged() && !hasNoteChanged() && !hasConfigurationsChanged()) ||
                  allConfigurationsRemoved() ||
                  addConfigurationsToSuiteQuery.isLoading ||
                  deleteConfigurationsFromSuiteQuery.isLoading ||
                  updateGeneralSettingsInSuiteQuery.isLoading
                }
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="s">
            <GeneralSettingsContainer
              name={title}
              handleSetName={setTitle}
              date={neededBy}
              handleSetDate={setNeededBy}
              notes={note ? note : ''}
              handleSetNotes={setNote}
            />
            <ConfigurationsContainer configurations={configurations} handleSetConfigurations={setConfigurations} />
            <ExpandableSection data-testid="suites-edit-queriescontainer" header="Add Queries">
              <SpaceBetween direction="vertical" size="s">
                <QueryContainer queries={queries} handleSetQueries={setQueries} />
              </SpaceBetween>
            </ExpandableSection>
          </SpaceBetween>
        </Form>
      </Box>
    );
  };

  return (
    <Box textAlign="center">
      {getSuiteByIdQuery.isError && showError()}
      {getSuiteByIdQuery.isLoading && showSpinner()}
      {getSuiteByIdQuery.isSuccess && showContent()}
    </Box>
  );
};
export default SuitesEdit;
