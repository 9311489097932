import { DeviceType } from './TodRunConfigurationGenerator';

export const deviceToTestGroupMap: Record<DeviceType, Record<'classic' | 'row', string[]>> = {
  android: {
    classic: ['channels_android_localization_classic', 'prime_android_localization_classic'],
    row: ['channels_android_localization_row', 'prime_android_localization_row'],
  },
  appletv: { classic: [], row: [] },
  firetv: {
    classic: ['channels_firetv_localization_classic', 'prime_firetv_localization_classic'],
    row: ['channels_firetv_localization_row', 'prime_firetv_localization_row'],
  },
  ios: {
    classic: ['prime_ios_localization_classic'],
    row: ['prime_ios_localization_row'],
  },
  livingroom: { classic: [], row: [] },
  web: {
    classic: ['channels_web_localization_classic', 'prime_web_localization_classic'],
    row: ['channels_web_localization_row', 'prime_web_localization_row'],
  },
};
export type Region = 'EU' | 'NA' | 'FE';
export type Country =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UK'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW';
export const CLASSIC_COUNTRIES: Array<Country> = ['US', 'GB', 'UK', 'JP', 'DE'];
export const CLASSIC_EU_COUNTRIES: Array<Country> = ['GB', 'UK', 'DE'];
export const CLASSIC_FE_COUNTRIES: Array<Country> = ['JP'];
export const CLASSIC_NA_COUNTRIES: Array<Country> = ['US'];
export const ROE_EU_COUNTRIES: Array<Country> = [
  'AD',
  'AI',
  'AL',
  'AM',
  'AT',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BL',
  'BM',
  'BV',
  'BY',
  'CH',
  'CW',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FK',
  'FO',
  'FR',
  'GB',
  'GE',
  'GF',
  'GG',
  'GI',
  'GL',
  'GP',
  'GR',
  'GS',
  'HR',
  'HU',
  'IE',
  'IM',
  'IO',
  'IS',
  'IT',
  'JE',
  'KM',
  'KN',
  'KY',
  'LC',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MF',
  'MK',
  'MQ',
  'MS',
  'MT',
  'NC',
  'NL',
  'NO',
  'PF',
  'PL',
  'PM',
  'PT',
  'RE',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'TC',
  'TF',
  'TR',
  'UA',
  'VA',
  'VC',
  'VG',
  'WF',
  'YT',
];
export const ROW_EU_COUNTRIES: Array<Country> = [
  'AE',
  'AF',
  'AO',
  'BD',
  'BF',
  'BH',
  'BI',
  'BJ',
  'BT',
  'BW',
  'CD',
  'CF',
  'CG',
  'CI',
  'CM',
  'CN',
  'CU',
  'CV',
  'DJ',
  'DZ',
  'EG',
  'EH',
  'ER',
  'ET',
  'GA',
  'GH',
  'GM',
  'GN',
  'GQ',
  'GW',
  'IL',
  'IN',
  'IQ',
  'IR',
  'JO',
  'KE',
  'KG',
  'KP',
  'KW',
  'KZ',
  'LB',
  'LK',
  'LR',
  'LS',
  'LY',
  'MA',
  'MG',
  'ML',
  'MR',
  'MU',
  'MV',
  'MW',
  'MZ',
  'NA',
  'NE',
  'NG',
  'NP',
  'OM',
  'PK',
  'PS',
  'QA',
  'RW',
  'SA',
  'SC',
  'SD',
  'SH',
  'SL',
  'SN',
  'SO',
  'SS',
  'ST',
  'SY',
  'SZ',
  'TD',
  'TG',
  'TJ',
  'TM',
  'TN',
  'TZ',
  'UG',
  'UZ',
  'XK',
  'YE',
  'ZA',
  'ZM',
  'ZW',
];
export const ROW_NA_COUNTRIES: Array<Country> = [
  'AG',
  'AQ',
  'AR',
  'AS',
  'BB',
  'BR',
  'BS',
  'BZ',
  'CA',
  'CL',
  'CO',
  'CR',
  'DM',
  'DO',
  'EC',
  'GD',
  'GT',
  'GU',
  'GY',
  'HN',
  'HT',
  'JM',
  'MP',
  'MX',
  'NI',
  'PA',
  'PE',
  'PR',
  'PY',
  'SR',
  'SV',
  'TT',
  'UM',
  'UY',
  'VE',
  'VI',
];
export const ROW_FE_COUNTRIES: Array<Country> = [
  'AU',
  'BN',
  'BO',
  'CC',
  'CK',
  'CX',
  'FJ',
  'FM',
  'HK',
  'HM',
  'ID',
  'KH',
  'KI',
  'KR',
  'LA',
  'MH',
  'MM',
  'MN',
  'MO',
  'MY',
  'NF',
  'NR',
  'NU',
  'NZ',
  'PG',
  'PH',
  'PN',
  'PW',
  'SB',
  'SG',
  'TH',
  'TK',
  'TL',
  'TO',
  'TV',
  'TW',
  'VN',
  'VU',
  'WS',
  'ZW',
];
export const EU_COUNTRIES: Array<Country> = [...ROW_EU_COUNTRIES, ...ROE_EU_COUNTRIES, ...CLASSIC_EU_COUNTRIES];
export const NA_COUNTRIES: Array<Country> = [...ROW_NA_COUNTRIES, ...CLASSIC_NA_COUNTRIES];
export const FE_COUNTRIES: Array<Country> = [...ROW_FE_COUNTRIES, ...CLASSIC_FE_COUNTRIES];
export const regionToCountryMap: Record<Region, Country[]> = {
  EU: EU_COUNTRIES,
  NA: NA_COUNTRIES,
  FE: FE_COUNTRIES,
};
export const isClassic = (country: Country) => {
  return CLASSIC_COUNTRIES.includes(country);
};
export const getRegion = (country: Country) => {
  const keys = Object.keys(regionToCountryMap) as Array<Region>;
  return keys.find((key) => regionToCountryMap[key].includes(country));
};
