import React, { createContext, useState } from 'react';
import { ITestCaseResult } from '../components/Suites/SuiteDataAggregation';

interface ITestCasesContext {
  testCases: ITestCaseResult[];
  setTestCases: React.Dispatch<React.SetStateAction<ITestCaseResult[]>>;
  nextTestCase: (currentTestCaseId: string) => ITestCaseResult;
  previousTestCase: (currentTestCaseId: string) => ITestCaseResult;
  hasNext: (currentTestCaseId: string) => boolean;
  hasPrevious: (currentTestCaseId: string) => boolean;
}

export const TestCasesContext = createContext<ITestCasesContext>({
  testCases: [],
  setTestCases: () => {},
  hasPrevious: () => {
    return false;
  },
  hasNext: () => {
    return false;
  },
  nextTestCase: () => {
    return { testResultId: '', testResultMetadataMap: {}, screenshotUrl: '', screenshots: [] };
  },
  previousTestCase: () => {
    return { testResultId: '', testResultMetadataMap: {}, screenshotUrl: '', screenshots: [] };
  },
});

export const TestCasesContextProvider: React.FC = ({ children }) => {
  const [testCases, setTestCases] = useState<ITestCaseResult[]>([]);

  const nextTestCase = (currentTestCaseId: string) => {
    return testCases[testCases.findIndex((element) => element.testResultId === currentTestCaseId) + 1];
  };
  const previousTestCase = (currentTestCaseId: string) => {
    return testCases[testCases.findIndex((element) => element.testResultId === currentTestCaseId) - 1];
  };

  const hasNext = (currentTestCaseId: string) => {
    return testCases.findIndex((element) => element.testResultId === currentTestCaseId) < testCases.length - 1;
  };
  const hasPrevious = (currentTestCaseId: string) => {
    return testCases.findIndex((element) => element.testResultId === currentTestCaseId) > 0;
  };

  return (
    <TestCasesContext.Provider
      value={{
        testCases: testCases,
        setTestCases: setTestCases,
        nextTestCase: nextTestCase,
        previousTestCase: previousTestCase,
        hasNext: hasNext,
        hasPrevious: hasPrevious,
      }}
    >
      {children}
    </TestCasesContext.Provider>
  );
};
