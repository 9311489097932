import { FlashbarProps } from '@amzn/awsui-components-react-v3';
import React, { createContext, useReducer } from 'react';

interface INotificationContext {
  flashbarItems: FlashbarProps.MessageDefinition[];
  showErrorFlashbar: (msg: string) => void;
  showSuccessFlashbar: (msg: string) => void;
  showWarningFlashbar: (msg: string) => void;
  showInProgressFlashbar: (msg: string) => void;
  clearFlashbar: () => void;
}

export const NotificationContext = createContext<INotificationContext>({
  flashbarItems: [],
  showErrorFlashbar: () => {},
  showSuccessFlashbar: () => {},
  showWarningFlashbar: () => {},
  showInProgressFlashbar: () => {},
  clearFlashbar: () => {},
});

const FLASHBAR_ERROR_ADD = 'FLASHBAR_ERROR_ADD';
const FLASHBAR_SUCCESS_ADD = 'FLASHBAR_SUCCESS_ADD';
const FLASHBAR_WARNING_ADD = 'FLASHBAR_WARNING_ADD';
const FLASHBAR_INPROGRESS_ADD = 'FLASHBAR_INPROGRESS_ADD';
const FLASHBAR_CLEAR = 'FLASHBAR_CLEAR';

const reducer = (
  state: FlashbarProps.MessageDefinition[],
  action: { type: string; payload: any }
): FlashbarProps.MessageDefinition[] => {
  if (action.type === FLASHBAR_ERROR_ADD) {
    return [
      {
        type: 'error',
        content: <span>{action.payload.message}</span>,
        dismissible: true,
        onDismiss: () => {
          action.payload.onDismiss();
        },
      },
    ];
  }
  if (action.type === FLASHBAR_SUCCESS_ADD) {
    return [
      {
        type: 'success',
        content: <span>{action.payload.message}</span>,
        dismissible: true,
        onDismiss: () => {
          action.payload.onDismiss();
        },
      },
    ];
  }
  if (action.type === FLASHBAR_WARNING_ADD) {
    return [
      {
        type: 'warning',
        content: <span>{action.payload.message}</span>,
        dismissible: true,
        onDismiss: () => {
          action.payload.onDismiss();
        },
      },
    ];
  }
  if (action.type === FLASHBAR_INPROGRESS_ADD) {
    return [
      {
        type: 'warning',
        content: <span>{action.payload.message}</span>,
        dismissible: false,
        loading: true,
      },
    ];
  }
  if (action.type === FLASHBAR_CLEAR) {
    return [];
  }
  return state;
};

export const NotificationProvider: React.FC = ({ children }) => {
  const [flashbarItems, dispatch] = useReducer(reducer, []);

  const showErrorFlashbar = (msg: string) => {
    dispatch({
      type: FLASHBAR_ERROR_ADD,
      payload: {
        message: msg,
        onDismiss: () => {
          clearFlashbar();
        },
      },
    });
  };
  const showSuccessFlashbar = (msg: string) => {
    dispatch({
      type: FLASHBAR_SUCCESS_ADD,
      payload: {
        message: msg,
        onDismiss: () => {
          clearFlashbar();
        },
      },
    });
  };
  const showWarningFlashbar = (msg: string) => {
    dispatch({
      type: FLASHBAR_WARNING_ADD,
      payload: {
        message: msg,
        onDismiss: () => {
          clearFlashbar();
        },
      },
    });
  };
  const showInProgressFlashbar = (msg: string) => {
    dispatch({
      type: FLASHBAR_INPROGRESS_ADD,
      payload: {
        message: msg,
      },
    });
  };
  const clearFlashbar = () => {
    dispatch({
      type: FLASHBAR_CLEAR,
      payload: {},
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        flashbarItems,
        showErrorFlashbar,
        showSuccessFlashbar,
        showWarningFlashbar,
        showInProgressFlashbar,
        clearFlashbar,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
